// Core variables and mixins
// @import "cea-bootstrap-variables.scss";

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";

$zindex-modal:             1060;

//@import "./node_modules/bootstrap/scss/mixins";

    // Vendor
    @import "./node_modules/bootstrap/scss/vendor/rfs";

    // Deprecate
    @import "./node_modules/bootstrap/scss/mixins/deprecate";

    // Utilities
    @import "./node_modules/bootstrap/scss/mixins/breakpoints";
    @import "./node_modules/bootstrap/scss/mixins/hover";
    @import "./node_modules/bootstrap/scss/mixins/image";
    @import "./node_modules/bootstrap/scss/mixins/badge";
    @import "./node_modules/bootstrap/scss/mixins/resize";
    @import "./node_modules/bootstrap/scss/mixins/screen-reader";
    @import "./node_modules/bootstrap/scss/mixins/size";
    @import "./node_modules/bootstrap/scss/mixins/reset-text";
    @import "./node_modules/bootstrap/scss/mixins/text-emphasis";
    @import "./node_modules/bootstrap/scss/mixins/text-hide";
    @import "./node_modules/bootstrap/scss/mixins/text-truncate";
    @import "./node_modules/bootstrap/scss/mixins/visibility";

    // Components
    @import "./node_modules/bootstrap/scss/mixins/alert";
    @import "./node_modules/bootstrap/scss/mixins/buttons";
    @import "./node_modules/bootstrap/scss/mixins/caret";
    @import "./node_modules/bootstrap/scss/mixins/pagination";
    @import "./node_modules/bootstrap/scss/mixins/lists";
    @import "./node_modules/bootstrap/scss/mixins/list-group";
    @import "./node_modules/bootstrap/scss/mixins/nav-divider";
    @import "./node_modules/bootstrap/scss/mixins/forms";
    @import "./node_modules/bootstrap/scss/mixins/table-row";

    // Skins
    @import "./node_modules/bootstrap/scss/mixins/background-variant";
    @import "./node_modules/bootstrap/scss/mixins/border-radius";
    @import "./node_modules/bootstrap/scss/mixins/box-shadow";
    @import "./node_modules/bootstrap/scss/mixins/gradients";
    @import "./node_modules/bootstrap/scss/mixins/transition";

    // Layout
    @import "./node_modules/bootstrap/scss/mixins/clearfix";
    //@import "./node_modules/bootstrap/scss/mixins/grid-framework";
    //@import "./node_modules/bootstrap/scss/mixins/grid";
    @import "./node_modules/bootstrap/scss/mixins/float";


@import "./node_modules/bootstrap/scss/root";
@import "./node_modules/bootstrap/scss/reboot";
@import "./node_modules/bootstrap/scss/type";
//@import "./node_modules/bootstrap/scss/images";
@import "./node_modules/bootstrap/scss/code";
//@import "./node_modules/bootstrap/scss/grid";
@import "./node_modules/bootstrap/scss/tables";
@import "./node_modules/bootstrap/scss/forms";
@import "./node_modules/bootstrap/scss/buttons";
@import "./node_modules/bootstrap/scss/transitions";
@import "./node_modules/bootstrap/scss/dropdown";
@import "./node_modules/bootstrap/scss/button-group";
@import "./node_modules/bootstrap/scss/input-group";
//@import "./node_modules/bootstrap/scss/custom-forms";
@import "./node_modules/bootstrap/scss/nav";
@import "./node_modules/bootstrap/scss/navbar";
@import "./node_modules/bootstrap/scss/card";
//@import "./node_modules/bootstrap/scss/breadcrumb";
@import "./node_modules/bootstrap/scss/pagination";
//@import "./node_modules/bootstrap/scss/badge";
//@import "./node_modules/bootstrap/scss/jumbotron";
@import "./node_modules/bootstrap/scss/alert";
//@import "./node_modules/bootstrap/scss/progress";
@import "./node_modules/bootstrap/scss/media";
@import "./node_modules/bootstrap/scss/list-group";
@import "./node_modules/bootstrap/scss/close";
//@import "./node_modules/bootstrap/scss/toasts";
@import "./node_modules/bootstrap/scss/modal";
//@import "./node_modules/bootstrap/scss/tooltip";
//@import "./node_modules/bootstrap/scss/popover";
//@import "./node_modules/bootstrap/scss/carousel";
//@import "./node_modules/bootstrap/scss/spinners";
@import "./node_modules/bootstrap/scss/utilities";
@import "./node_modules/bootstrap/scss/print";
