@import url(//fonts.googleapis.com/css?family=Paprika|Fjalla+One|Source+Sans+Pro:300,400,600,700,900);
@import "./variables.scss";
@import "./_cea-bootstrap.scss";

html {
    height: 100%;

    body {
        font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.5;
        text-align: left;
        color: #77787B;
        background-color: #fff;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.container.cea-page-container {
    &.admin-page {
        max-width: 1200px;
    }
}

.container {
    min-height: 100%;
    height: 100%;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%;
}


.create-dialog, .history-dialog {
    position: absolute;
    padding: 1.25em;
    top: 1%;
    left: 20%;
    border: 1px solid #000000;
}

.cea-page-container {
    .clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after, .nav:before, .nav:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after, .modal-footer:before, .modal-footer:after, .container-fluid-override:before, .container-fluid-override:after {
        content: " ";
        display: table;
    }

    .clearfix:after, .dl-horizontal dd:after, .container:after, .container-fluid:after, .row:after, .form-horizontal .form-group:after, .btn-toolbar:after, .btn-group-vertical > .btn-group:after, .nav:after, .navbar:after, .navbar-header:after, .navbar-collapse:after, .pager:after, .panel-body:after, .modal-footer:after, .container-fluid-override:after {
        clear: both;
    }

    .btn.btn-primary, .btn.cea-policy-btn {
        background-color: #1294b2;
        border-color: #1294b2;
    }

    .btn.btn-primary:hover, .btn.cea-policy-btn:hover, .btn.btn-primary:focus, .btn.cea-policy-btn:focus {
        background-color: #0f748b;
        border-color: #0d6d84;
    }

    .btn.btn-primary:active, .btn.cea-policy-btn:active, .btn.btn-primary.active, .btn.cea-policy-btn.active {
        background-color: #0d6d84 !important;
        border-color: #0c6478 !important;
    }

    .btn.cea-policy-btn {
        background-color: #1294b2;
        border-color: #1294b2;
        margin: 10px 8px;
        white-space: normal;
        vertical-align: middle !important;
        width: 150px;
        height: 150px;
        font-size: 18px;
    }

    .btn.cea-policy-btn.pro {
        width: 175px;
        font-size: 19px;
    }

    form input[type="text"], form select {
        border: thin solid #98968F !important;
        margin: 0 0 1rem !important;
        display: block !important;
        width: 100% !important;
        height: 46px !important;
        line-height: 46px !important;
        padding: 0px !important;
        border-radius: 0 !important;
    }

    form textarea {
        margin: 0 0 1rem !important;
        min-height: 80px;
        resize: none;
    }

    .input-group-addon, .input-group-btn, .input-group .form-control {
        display: table-cell;
    }

    .cea-help-btn {
        background-color: #f9f9f9 !important;
    }

    .btn {
        color: #fff;
        padding: .375rem .75rem;
        margin-bottom: 8px;
        text-transform: uppercase;
        font-weight: 700;
        border-radius: 0;
        white-space: nowrap;
        vertical-align: initial;
        cursor: pointer;
    }

    .cea-help-btn span {
        color: #268b91;
        top: 2px;
        margin-bottom: 5px;
        font-size: 15px !important;
    }

    .required:after {
        content: "*";
        color: red;
        font-size: 1.2em;
        display: inline-block;
        position: absolute;
        top: 16px;
        bottom: 32px;
        right: -9px;
    }

    .input-group-addon, .input-group-btn {
        width: 1%;
        white-space: nowrap;
        vertical-align: middle;
    }
    //---------------------------------------------

    h2 {
        font-family: 'Fjalla One', sans-serif;
        color: #0b3c5d;
        font-size: 24px;
        font-size: 1.5rem;
        font-weight: 400;
        text-transform: uppercase;
    }

    h1, .h1, h2, .h2, h3, .h3 {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .btn-link {
        color: #07819c !important;
    }

    .button-image .symbol {
        fill: #FFFFFF;
    }

    .button-image {
        width: 60px;
        height: 60px;
        color: #024f57;
        float: none;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        fill: #FFFFFF;
    }

    #wrap {
        min-height: 100%;
        height: 100%;
    }
    /* 
    .cea-visible-xxs, .cea-visible-xs, .cea-visible-sm, .cea-visible-md, .cea-visible-lg {
        display: none !important;
    }

    .cea-visible-xxs-block, .cea-visible-xxs-inline, .cea-visible-xxs-inline-block, .cea-visible-xs-block, .cea-visible-xs-inline, .cea-visible-xs-inline-block, .cea-visible-sm-block, .cea-visible-sm-inline, .cea-visible-sm-inline-block, .cea-visible-md-block, .cea-visible-md-inline, .cea-visible-md-inline-block, .cea-visible-lg-block, .cea-visible-lg-inline, .cea-visible-lg-inline-block {
        display: none !important;
    }

    .cea-visible-print {
        display: none !important;
    }
        */

    .cea-visible-xxs-block, .cea-visible-xs {
        display: none;
    }
}

.cea-page-container {
    container-type: inline-size;
    container-name: cea-container;
    width: 100%;
    max-width: 915px;
    border: 0;
    height: 100%;
    position: relative;
    margin: auto auto;
    overflow-y: auto;
}

@container cea-container (max-width: #{$screen-xxs-max}) {
    .cea-visible-xxs {
        display: block !important;
    }

    table.cea-visible-xxs {
        display: table;
    }

    tr.cea-visible-xxs {
        display: table-row !important;
    }

    th.cea-visible-xxs, td.cea-visible-xxs {
        display: table-cell !important;
    }
}

@container cea-container (max-width: #{$screen-xxs-max}) {
    .cea-visible-xxs-block {
        display: block !important;
    }
}

@container cea-container (max-width: #{$screen-xxs-max}) {
    .cea-visible-xxs-inline {
        display: inline !important;
    }
}

@container cea-container (max-width: #{$screen-xxs-max}) {
    .cea-visible-xxs-inline-block {
        display: inline-block !important;
    }
}

@container cea-container (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
    .cea-visible-xs {
        display: block !important;
    }

    table.cea-visible-xs {
        display: table;
    }

    tr.cea-visible-xs {
        display: table-row !important;
    }

    th.cea-visible-xs, td.cea-visible-xs {
        display: table-cell !important;
    }
}

@container cea-container (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
    .cea-visible-xs-block {
        display: block !important;
    }
}

@container cea-container (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
    .cea-visible-xs-inline-block {
        display: inline-block !important;
    }
}

@container cea-container (min-width: #{$screen-sm}) and (max-width: #{$screen-sm-max}) {
    .cea-visible-sm {
        display: block !important;
    }

    table.cea-visible-sm {
        display: table;
    }

    tr.cea-visible-sm {
        display: table-row !important;
    }

    th.cea-visible-sm, td.cea-visible-sm {
        display: table-cell !important;
    }
}

@container cea-container (min-width: #{$screen-sm}) and (max-width: #{$screen-sm-max}) {
    .cea-visible-sm-block {
        display: block !important;
    }
}

@container cea-container (min-width: #{$screen-sm}) and (max-width: #{$screen-sm-max}) {
    .cea-visible-sm-inline {
        display: inline !important;
    }
}

@container cea-container (min-width: #{$screen-sm}) and (max-width: #{$screen-sm-max}) {
    .cea-visible-sm-inline-block {
        display: inline-block !important;
    }
}

@container cea-container (min-width: #{$screen-md}) and (max-width: #{$screen-md-max}) {
    .cea-visible-md {
        display: block !important;
    }

    table.cea-visible-md {
        display: table;
    }

    tr.cea-visible-md {
        display: table-row !important;
    }

    th.cea-visible-md, td.cea-visible-md {
        display: table-cell !important;
    }
}

@container cea-container (min-width: #{$screen-md}) and (max-width: #{$screen-md-max}) {
    .cea-visible-md-block {
        display: block !important;
    }
}

@container cea-container (min-width: #{$screen-md}) and (max-width: #{$screen-md-max}) {
    .cea-visible-md-inline {
        display: inline !important;
    }
}

@container cea-container (min-width: #{$screen-md}) and (max-width: #{$screen-md-max}) {
    .cea-visible-md-inline-block {
        display: inline-block !important;
    }
}

@container cea-container (min-width: #{$screen-lg}) {
    .cea-visible-lg {
        display: block !important;
    }

    table.cea-visible-lg {
        display: table;
    }

    tr.cea-visible-lg {
        display: table-row !important;
    }

    th.cea-visible-lg, td.cea-visible-lg {
        display: table-cell !important;
    }
}

@container cea-container (min-width: #{$screen-lg}) {
    .cea-visible-lg-block {
        display: block !important;
    }
}

@container cea-container (min-width: #{$screen-lg}) {
    .cea-visible-lg-inline {
        display: inline !important;
    }
}

@container cea-container (min-width: #{$screen-lg}) {
    .cea-visible-lg-inline-block {
        display: inline-block !important;
    }
}

@container cea-container (max-width: #{$screen-xs-max}) {
    .cea-hidden-xs {
        display: none !important;
    }
}

@container cea-container (min-width: #{$screen-sm}) and (max-width: #{$screen-sm-max}) {
    .cea-hidden-sm {
        display: none !important;
    }
}

@container cea-container (min-width: #{$screen-md}) and (max-width: #{$screen-md-max}) {
    .cea-hidden-md {
        display: none !important;
    }
}

@container cea-container (min-width: #{$screen-lg}) {
    .cea-hidden-lg {
        display: none !important;
    }
}

/* Non Container Supporter Browsers fallback */

@supports not (contain: inline-size) {
    @media (max-width: #{$screen-xxs-max}) {
        .cea-visible-xxs {
            display: block !important;
        }

        table.cea-visible-xxs {
            display: table;
        }

        tr.cea-visible-xxs {
            display: table-row !important;
        }

        th.cea-visible-xxs, td.cea-visible-xxs {
            display: table-cell !important;
        }
    }

    @media (max-width: #{$screen-xxs-max}) {
        .cea-visible-xxs-block {
            display: block !important;
        }
    }

    @media (max-width: #{$screen-xxs-max}) {
        .cea-visible-xxs-inline {
            display: inline !important;
        }
    }

    @media (max-width: #{$screen-xxs-max}) {
        .cea-visible-xxs-inline-block {
            display: inline-block !important;
        }
    }

    @media (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
        .cea-visible-xs {
            display: block !important;
        }

        table.cea-visible-xs {
            display: table;
        }

        tr.cea-visible-xs {
            display: table-row !important;
        }

        th.cea-visible-xs, td.cea-visible-xs {
            display: table-cell !important;
        }
    }

    @media (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
        .cea-visible-xs-block {
            display: block !important;
        }
    }

    @media (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
        .cea-visible-xs-inline-block {
            display: inline-block !important;
        }
    }

    @media (min-width: #{$screen-sm}) and (max-width: #{$screen-sm-max}) {
        .cea-visible-sm {
            display: block !important;
        }

        table.cea-visible-sm {
            display: table;
        }

        tr.cea-visible-sm {
            display: table-row !important;
        }

        th.cea-visible-sm, td.cea-visible-sm {
            display: table-cell !important;
        }
    }

    @media (min-width: #{$screen-sm}) and (max-width: #{$screen-sm-max}) {
        .cea-visible-sm-block {
            display: block !important;
        }
    }

    @media (min-width: #{$screen-sm}) and (max-width: #{$screen-sm-max}) {
        .cea-visible-sm-inline {
            display: inline !important;
        }
    }

    @media (min-width: #{$screen-sm}) and (max-width: #{$screen-sm-max}) {
        .cea-visible-sm-inline-block {
            display: inline-block !important;
        }
    }

    @media (min-width: #{$screen-md}) and (max-width: #{$screen-md-max}) {
        .cea-visible-md {
            display: block !important;
        }

        table.cea-visible-md {
            display: table;
        }

        tr.cea-visible-md {
            display: table-row !important;
        }

        th.cea-visible-md, td.cea-visible-md {
            display: table-cell !important;
        }
    }

    @media (min-width: #{$screen-md}) and (max-width: #{$screen-md-max}) {
        .cea-visible-md-block {
            display: block !important;
        }
    }

    @media (min-width: #{$screen-md}) and (max-width: #{$screen-md-max}) {
        .cea-visible-md-inline {
            display: inline !important;
        }
    }

    @media (min-width: #{$screen-md}) and (max-width: #{$screen-md-max}) {
        .cea-visible-md-inline-block {
            display: inline-block !important;
        }
    }

    @media (min-width: #{$screen-lg}) {
        .cea-visible-lg {
            display: block !important;
        }

        table.cea-visible-lg {
            display: table;
        }

        tr.cea-visible-lg {
            display: table-row !important;
        }

        th.cea-visible-lg, td.cea-visible-lg {
            display: table-cell !important;
        }
    }

    @media (min-width: #{$screen-lg}) {
        .cea-visible-lg-block {
            display: block !important;
        }
    }

    @media (min-width: #{$screen-lg}) {
        .cea-visible-lg-inline {
            display: inline !important;
        }
    }

    @media (min-width: #{$screen-lg}) {
        .cea-visible-lg-inline-block {
            display: inline-block !important;
        }
    }

    @media (max-width: #{$screen-xs-max}) {
        .cea-hidden-xs {
            display: none !important;
        }
    }

    @media (min-width: #{$screen-sm}) and (max-width: #{$screen-sm-max}) {
        .cea-hidden-sm {
            display: none !important;
        }
    }

    @media (min-width: #{$screen-md}) and (max-width: #{$screen-md-max}) {
        .cea-hidden-md {
            display: none !important;
        }
    }

    @media (min-width: #{$screen-lg}) {
        .cea-hidden-lg {
            display: none !important;
        }
    }
}

@container cea-container print {
    .cea-visible-print {
        display: block !important;
    }

    table.cea-visible-print {
        display: table;
    }

    tr.cea-visible-print {
        display: table-row !important;
    }

    th.cea-visible-print, td.cea-visible-print {
        display: table-cell !important;
    }
}

.cea-page-container {
    .cea-visible-print-block {
        display: none !important;
    }

    .cea-visible-print-inline {
        display: none !important;
    }

    .cea-visible-print-inline-block {
        display: none !important;
    }
}

@container cea-container print {
    .cea-visible-print-block {
        display: block !important;
    }
}

@container cea-container print {
    .cea-visible-print-inline {
        display: inline !important;
    }
}

@container cea-container print {
    .cea-visible-print-inline-block {
        display: inline-block !important;
    }
}

@container cea-container print {
    .cea-hidden-print {
        display: none !important;
    }
}

.cea-page-container {
    a {
        color: #1294b2;
        text-decoration: none !important;
    }

    a:hover, a:focus {
        color: #23527c;
        text-decoration: underline;
    }

    .cea-main-view {
        padding-right: 15px !important;
        padding-left: 2px !important;
    }

    .container-fluid-override {
        margin-right: auto;
        margin-left: auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    .panels-column {
        //padding-left: 15px;
        //padding-right: 15px;
        float: left;
        width: 50%;
        padding-left: 6px;
        padding-right: 6px;
        position: relative;
        min-height: 1px;
        text-align: center;
        border-bottom-width: 0px;
        border-top-width: 0px;
        border-left-width: 0px;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .btn-primary.disabled, .btn-primary:disabled {
        color: #fff !important;
        background-color: #1294b2 !important;
        border-color: #1294b2 !important;
    }

    .cea-help-link span:hover, .cea-help-link span:focus, .cea-help-clear-btn span:hover, .cea-help-clear-btn span:focus {
        color: #10407e !important;
    }
}


@container cea-container (max-width: #{$screen-sm-max}) {
    .form-control {
        font-size: 12px !important;
    }
}

@container cea-container (min-width: #{$screen-sm}) {
    .container-fluid-override {
        width: auto;
    }
}

@container cea-container (min-width: #{$screen-md}) {
    .container-fluid-override {
        width: auto;
    }
}

@container cea-container (min-width: #{$screen-lg}) {
    .container-fluid-override {
        width: auto;
    }
}


/* Home SCSS Tags */

$white: #fff;
$btn-tags: '.cea-policy-btn, .cea-policy-btn:active, .cea-policy-btn:hover, .cea-policy-btn:visited';
$btn-tags-hover: '.cea-policy-btn:active, .cea-policy-btn:hover';


@mixin divpage-mobile {
    @media screen and (max-width: #{$screen-sm}) {
        .page-col {
            //margin-left: 0;
            max-width: 100%;
        }
    }
}

.cea-page-container {
    #{$btn-tags} {
        color: $white;
    }

    #{$btn-tags-hover} {
        color: $white;
    }
}

@container cea-container (min-width: #{$screen-sm}) and (max-width: #{$screen-sm-max}) {
    .cea-col-sm-9 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .cea-offset-sm-0 {
        margin-left: 0;
    }

    .cea-offset-sm-2 {
        margin-left: 16.66666667%;
    }

    .cea-col-sm-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .cea-col-sm-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}

@container cea-container (min-width: #{$screen-md}) and (max-width: #{$screen-md-max}) {
    .cea-offset-md-1 {
        margin-left: 8.33333333%;
    }

    .cea-offset-md-3 {
        margin-left: 25%;
    }

    .cea-col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .cea-col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
}

@container cea-container (min-width: #{$screen-lg}) {
    .cea-offset-lg-3 {
        margin-left: 25%;
    }

    .cea-col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
}

.cea-page {
    .cea-main-view {
        .start {
            .row {
                display: block;

                button.btn {
                    font-size: 12px;
                }

                button.center-block {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }

                @include divpage-mobile;

                div {
                    button.cea-policy-btn {
                        font-size: 18px;

                        :hover {
                            color: #fff;
                        }
                    }

                    div.btn {
                        button.btn {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

.modal-dialog {
    h2 {
        font-family: 'Fjalla One', sans-serif;
        color: #0b3c5d;
        font-size: 24px;
        font-size: 1.5rem;
        font-weight: 400;
        text-transform: uppercase;
    }

    h1, .h1, h2, .h2, h3, .h3 {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .cea-main-view {
        .alert {
            padding: 10px;
            font-size: 12px;
            margin: -4px 3px 8px 2px;
        }
    }

    .header-container {
        font-size: 16px;

        h2 {
            margin-left: 0px;
            margin-right: 0px;
            display: block;
        }

        div {
            margin-left: 0px;
            margin-right: 0px;
            display: block;
        }
    }

    .btn-link {
        color: #07819c !important;
    }

    .btn.btn-primary {
        background-color: #1294b2;
        border-color: #1294b2;
    }

    .btn-primary.disabled, .btn-primary:disabled {
        color: #fff !important;
        background-color: #1294b2 !important;
        border-color: #1294b2 !important;
    }
}


@container cea-container (max-width: #{$screen-xxxs-max}) {
    .cea-hidden-xss {
        display: none !important;
    }
}

@container cea-container (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
    .cea-hidden-xs {
        display: none !important;
    }
}



@supports not (contain: inline-size) {
    @media (max-width: #{$screen-xxxs-max}) {
        .cea-hidden-xss {
            display: none !important;
        }
    }

    @media (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
        .cea-hidden-xs {
            display: none !important;
        }
    }

    @media (min-width: #{$screen-sm}) and (max-width: #{$screen-sm-max}) {
        .cea-ns-hidden-sm {
            display: none !important;
        }

        .cea-ns-hidden-xxs {
            display: none !important;
        }

        .cea-ns-visible-xxs {
            display: block !important;
        }

        .cea-ns-col-sm-12 {
            width: 100% !important;
        }
    }

    @media screen and (max-width: #{$screen-sm}) {
        .page-col {
            //margin-left: 0;
            width: 100%;
        }
    }
}
/* Homeowner, Renter, Condo & Mobilehome SCSS */

@mixin header-language-max-768 {
    @container cea-container (min-width: #{$screen-sm}) {
        .header-language {
            margin-right: 35px;
        }
    }
}

@mixin header-language-simple-btn-768 {
    @container cea-container (max-width: #{$screen-sm}) and (min-width: 321px) {
        .btn {
            display: block;
        }
    }
}

.cea-header-view {
    .row {
        margin-left: -15px;
        margin-right: -15px;

        div {
            .header-container {
                .condo {
                    padding: 20px 105px 0 105px !important;
                }

                .simple-header {
                    background-color: #0B3C5D;
                    background-image: none;
                    color: #fff;
                    margin: 0;
                    padding: 20px 20px 0 20px;
                    margin-bottom: 40px; //give more padding but only on larger screens (compiles correct)
                    @container (min-width: #{$screen-sm}) {
                        padding: 20px 120px 0 120px;
                    }

                    .title {
                        font-size: 32px;
                        text-align: center;
                        display: block;
                    }

                    .subtitle {
                        margin-top: 10px;
                        text-align: center;
                        font-size: 14px;
                        display: block;
                    }

                    .header-image {
                        width: 50px;
                        height: 50px;
                        color: #024f57;
                        float: none;
                        position: relative;
                        margin-left: auto;
                        margin-right: auto;
                        fill: #FFFFFF;
                        top: 30px;
                        margin-bottom: -5px;
                        margin-top: -5px;

                        @container cea-container (max-width: #{$screen-xxs-max}) {
                            top: -10px;
                            margin-top: 20px;
                        }
                    }
                }
            }

            @include header-language-max-768;

            .header-language {
                float: right;
                margin-top: -30px;

                .btn {
                    color: #fff;
                    padding: .375rem .75rem;
                    margin-bottom: 8px;
                    text-transform: uppercase;
                    font-weight: 700;
                    border-radius: 0;
                    white-space: nowrap;
                    vertical-align: initial;
                    font-size: 12px;
                }

                .btn-link {
                    color: #07819c !important;
                }
            }
        }
    }
}

@container cea-container (min-width: #{$screen-sm}) {
    .cea-col-sm-12 {
        width: 100%;
    }
}

.cea-page-container {
    svg {
        fill: currentColor;

        .symbol-back {
            fill: #0B3C5D !important;
        }

        .symbol-border, .symbol {
            fill: #fff !important;
        }
    }
}


/* General / Questions SCSS */

.cea-page {
    .cea-main-view {
        .general-form {
            .cea-form {
                padding-top: 1em;
                display: block;
                margin-left: auto;
                margin-right: auto;
                font-size: 14px;
                font-weight: normal;

                #form-container {
                    .cea-top-margin20 {
                        margin-top: 20px;

                        .btn-primary {
                            background-color: #1294b2;
                            border-color: #1294b2;
                            color: #fff;
                            padding: .375rem .75rem;
                            margin-bottom: 8px;
                            font-size: 12px;
                        }
                    }
                }

                #form-views {
                    .alert-danger {
                        margin: -1.3rem 0.2rem 1.4rem !important;
                        padding: 0 40px 0 15px !important;
                        color: #bd0102 !important;
                        font-weight: 700 !important;
                        font-size: 12px !important;
                        font-size: 0.75rem !important;
                        background-color: transparent !important;
                        border: none !important;
                    }
                }
            }
        }
    }
}

.cea-page-container {
    .cea-help-btn span {
        //color: #1874ab;
        color: #07819c !important;
        font-size: 15px !important;
    }

    form {
        .has-error {
            .drop-down-arrow {
                border-color: #bd0102 !important;
            }

            .form-control {
                border-color: #bd0102 !important;
            }
        }

        .form-group {
            padding: 3px 3px 8px 2px;
            margin-bottom: 0;
            position: relative;

            .cea-radio-row {
                margin-left: -15px;
                margin-right: -15px;

                .cea-radio-labels {
                    position: relative;
                    min-height: 1px;
                    float: left;
                    width: 58.33333333%;

                    label {
                        padding-top: 5px;
                        font-size: 14px;
                        font-weight: normal;
                        display: block;
                        text-transform: uppercase;
                        margin: 0 0 4px
                    }
                }

                @container cea-container (min-width: #{$screen-sm}) {
                    .cea-radio-buttons {
                        float: left;
                        width: 100%;
                    }
                }

                @container cea-container (max-width: #{$screen-sm}) {
                    .cea-radio-buttons {
                        padding-right: 0 !important;
                    }
                }

                @supports not (contain: inline-size) {
                    @media (min-width: #{$screen-sm}) {
                        .cea-radio-buttons {
                            float: left;
                            width: 100%;
                        }
                    }

                    @media (max-width: #{$screen-sm}) {
                        .cea-radio-buttons {
                            padding-right: 0 !important;
                        }
                    }
                }

                .cea-radio-buttons {
                    position: relative;
                    min-height: 1px;
                    padding-left: 15px;
                    padding-right: 15px;

                    .radio-group-accessories {
                        float: right;

                        .radio-inline {
                            display: inline-block;
                            padding-left: 20px;
                            margin-bottom: 0;
                            vertical-align: middle;
                            font-weight: normal;
                            cursor: pointer;
                        }

                        label {
                            display: inline-block;
                            margin-bottom: 0.5rem;

                            input[type="radio"] {
                                margin: 4px 0 0;
                                position: absolute;
                                margin-left: -20px;
                            }
                        }

                        .no-caption {
                            padding-right: 12px;
                        }

                        .button-caption {
                            padding-top: 6px;
                            float: right;
                            padding-right: 17px;
                            text-transform: uppercase;
                        }

                        .cea-help-link {
                            float: right;
                            padding-right: 11px;
                            border-right-width: 0;
                            position: relative;
                            top: -3px;
                            color: #1874ab;
                        }
                    }
                }
            }

            .input-group {
                table {
                    white-space: nowrap;
                    text-align: right;
                    display: inline-block;
                }
            }
        }

        .alert-danger {
            margin: -1.3rem 0.2rem 1.4rem !important;
            padding: 0 40px 0 15px !important;
            color: #bd0102 !important;
            font-weight: 700 !important;
            font-size: 12px !important;
            font-size: 0.75rem !important;
            background-color: transparent !important;
            border: none !important;
        }

        .cea-help-btn {
            height: 46px !important;
            margin: 0 0 1rem !important;
            border: thin solid #98968F !important;
            border-left: none !important;
        }

        .cea-help-btn.left {
            border: thin solid #98968F !important;
            border-right: none !important;
        }

        .input-group {
            border: none !important;
            border-radius: 0;
            width: 100%;
            position: relative;
            display: table;
            border-collapse: separate;
        }

        .drop-down-wrapper {
            height: 46px;
            margin-bottom: 1rem;

            .drop-down-arrow {
                position: relative;
                margin: 0 0 1rem;
                border: thin solid #98968F;
                height: 46px !important;

                select, select.form-control:not([size]):not([multiple]) {
                    width: 100% !important;
                    height: 43px !important;
                    padding: 0 8px 0 15px !important;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    background: #fff !important;
                    border: none !important;
                }
            }

            .drop-down-arrow::after {
                content: "\f0d7";
                display: block;
                position: absolute;
                top: 50%;
                right: 11px;
                transform: translate(0, -50%);
                font-family: 'Font Awesome 5 Free', Helvetica, Arial, Verdana, Tahoma, sans-serif;
                pointer-events: none;
                font-size: 22px;
                font-size: 1.375rem;
                font-weight: 900;
                z-index: 1000;
            }
        }

        input[type="text"], form select {
            appearance: none !important;
            border: thin solid #98968F !important;
            margin: 0 0 1rem !important;
            display: block !important;
            width: 100% !important;
            height: 46px !important;
            line-height: 46px !important;
            padding: 0 15px !important;
            border-radius: 0 !important;
        }

        .form-control {
            color: #495057 !important;
            background-color: #fff !important;
            border-color: #98968F !important;
            outline: 0;
        }

        .has-success {
            .form-control {
                border-color: #000000;
                -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            }
        }
    }

    .radio-group-collapse {
        .cea-radio-row {
            display: table;
            width: 100%;
        }
    }

    .cea-radio-row-adjustment {
        .cea-radio-row {
            display: table;
            width: 100%;
            margin-left: -15px;
            margin-right: -15px;

            @container cea-container (min-width: #{$screen-sm}) {
                .cea-radio-col {
                    float: left;
                    width: 83.33333333%;
                    margin-left: 0%;
                }
            }

            .cea-radio-col {
                padding-left: 15px;
                padding-right: 15px;
                position: relative;
                min-height: 1px;
            }

            @container cea-container (min-width: #{$screen-sm}) {
                .cea-radio-help {
                    float: left;
                    width: 16.66666667%;
                    margin-left: 0%;
                }
            }

            @container cea-container (max-width: #{$screen-xs-max}) and (min-width: #{$screen-xs}) {
                .cea-radio-col {
                    padding-left: 15px;
                    padding-right: 15px;
                    float: left;
                    width: 83.33333333%;
                }
            }

            .cea-radio-help {
                padding-left: 15px;
                padding-right: 15px;
                position: relative;
                min-height: 1px;
            }

            @supports not (contain: inline-size) {
                @media (min-width: #{$screen-sm}) {
                    .cea-radio-col {
                        float: left;
                        width: 83.33333333%;
                        margin-left: 0%;
                    }
                }

                @media (min-width: #{$screen-sm}) {
                    .cea-radio-help {
                        float: left;
                        width: 16.66666667%;
                        margin-left: 0%;
                    }
                }

                @media (max-width: #{$screen-xs-max}) and (min-width: #{$screen-xs}) {
                    .cea-radio-col {
                        padding-left: 15px;
                        padding-right: 15px;
                        float: left;
                        width: 83.33333333%;
                    }
                }
            }
        }
    }
}


@container cea-container (max-width: #{$screen-sm}) {
    .row.cea-saving-buttons {
        display: table !important;
        width: 100%;
    }

    .cea-col-sm-6 {
        width: 50% !important;
        display: inline-block;
    }

    .cea-radio-row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .cea-radio-labels {
        width: 100% !important;
    }
}

@container cea-container (max-width: #{$screen-xxs-max}) and (min-width: #{$screen-xxs}) {
    .cea-radio-col {
        padding-left: 0;
        padding-right: 0;
        float: left;
        width: 83.33333333%;
    }

    .cea-radio-help {
        padding-left: 0;
        padding-right: 0;
        float: left;
        width: 16.66666667%;
    }
}

.cea-page-container {
    .width-50 {
        padding-left: 10px;
        padding-right: 10px;
        flex: 1;
    }

    .submitBtn {
        max-width: 100%;
        flex-wrap: wrap;
        display: flex;
    }

    .ngb-datepicker.dropdown-menu {
        left: -40px !important;
    }

    *:not(input) {
        -ms-user-select: none;
    }

    .ngb-dp-weekday {
        color: #000 !important;
        font-weight: bold;
    }

    .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
        background-color: #45b1e8;
        border-color: #3ca0dd;
        color: #fff;
    }

    .custom-select {
        color: #000 !important;
        font-weight: bold;
        padding-left: 10px !important;
    }

    .form-select {
        color: #000 !important;
        font-weight: bold;
        padding-left: 10px !important;
    }

    .ngb-dp-weekday.small {
        font-style: normal !important;
    }

    .margin-1 {
        margin: 1rem 0 0 !important;
    }
}

@supports not (contain: inline-size) {
    @media (max-width: #{$screen-sm}) {
        .row.cea-saving-buttons {
            display: table !important;
            width: 100%;
        }

        .cea-col-sm-6 {
            width: 50% !important;
            display: inline-block;
        }

        .cea-radio-row {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        .cea-radio-labels {
            width: 100% !important;
        }
    }

    @media (max-width: #{$screen-xxs-max}) and (min-width: #{$screen-xxs}) {
        .cea-radio-col {
            padding-left: 0;
            padding-right: 0;
            float: left;
            width: 83.33333333%;
        }

        .cea-radio-help {
            padding-left: 0;
            padding-right: 0;
            float: left;
            width: 16.66666667%;
        }
    }
}
/* Help Button SCSS */


.cea-page-container {
    .cea-help-clear-btn span {
        color: #07819c !important;
        //color: #1874ab;
        font-size: 15px;
        top: 2px;
        margin-bottom: 5px;
    }

    .cea-help-link-radio {
        margin-top: 5px;
        float: right;
        padding-right: 11px;
        border-right-width: 0;
        color: #1874ab !important;
        position: relative;
        top: -5px;
    }

    .cea-help-btn-input {
        height: 46px !important;
        margin: 0 0 1rem !important;
        border: thin solid #98968F !important;
        border-left: none !important;
        //color: #1874ab !important;
        color: #07819c !important;
    }

    .cea-help-btn-header {
        width: 30px;
        margin-left: -5px;
        background-color: #0b3c5d !important;
        border: none !important;
        color: #f9f9f9 !important;
    }

    .font-icon-background {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background-color: #fff;
    }

    .cea-help-icon-header {
        position: relative;
        left: -13px;
        top: -1px;
        color: #1874ab !important;
    }
}


/* Calculations / Slider SCSS */

.cea-header-view {
    .header-language {
        float: right;
        margin-top: -30px;
    }

    .cea-page-header {
        border-width: 0;
        z-index: 1030;
        background: white;
        margin-bottom: 50px;

        div.font-icon-background {
            display: inline-block;
            width: 12px;
            height: 12px;
            /* color: white; */
            border-radius: 12px;
            background-color: white;
        }

        button.cea-help-btn {
            background-color: #0B3C5D !important;
            border: none !important;
            color: #f9f9f9 !important;

            span {
                color: #1874ab;
                z-index: 1;
            }
        }

        @container cea-container (min-width: #{$screen-sm}) {
            .container {
                //width: 750px;
            }
        }

        @container cea-container (min-width: #{$screen-md}) {
            .container {
                width: 970px;
            }
        }

        @container cea-container (min-width: #{$screen-lg}) {
            .container {
                width: 1170px;
            }
        }

        .container {
            min-height: 100%;
            height: 100%;
            margin-right: auto;
            margin-left: auto;
            padding-left: 15px;
            padding-right: 15px;

            .row {
                margin-left: -15px;
                margin-right: -15px;

                .header-area {
                    background-color: #0B3C5D;
                    background-image: none;
                    color: #fff;

                    .row {
                        margin-left: -15px;
                        margin-right: -15px;

                        .detailed-header {
                            background-color: #0B3C5D;
                            background-image: none;
                            color: #fff;
                            padding: 20px;
                            overflow: auto;

                            .header-image {
                                margin-top: 3px;
                                color: #0B3C5D;
                                width: 50px;
                                height: 50px;
                                float: none;
                                position: relative;
                                margin-left: auto;
                                margin-right: auto;
                                fill: #FFFFFF;
                            }

                            .title {
                                font-size: 24px;
                                font-weight: bold;
                                display: inline-block !important;
                                border-bottom: 2px solid #8cbad5;
                                padding-bottom: 3px;
                                padding-left: 5px;
                                padding-right: 5px;
                                margin-top: 0;
                                margin-bottom: 6px;
                                max-width: 230px;
                                text-align: center;
                                /*font-size: 32px;*/
                            }

                            .details {
                                font-size: 15px;
                                display: block;

                                span {
                                    font-weight: bold;
                                }
                            }
                        }

                        .guage-header {
                            .gauges-container {
                                display: flex;
                                /*margin-top: -165px;
                height: 277px;*/
                            }

                            .gauge-title {
                                font-size: 24px;
                                position: relative;
                                width: 100%;
                                text-align: center;
                            }

                            .gauge-subtitle {
                                position: relative;
                                width: 100%;
                                text-align: center;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.cea-page {
    .cea-main-view {
        form {
            .cea-form {
                padding-top: 1em;
                display: block;
                font-size: 14px;
                font-weight: normal;

                #form-container {
                    #form-views {
                        .cea-top-margin20 {
                            margin-top: 20px;
                        }

                        .cea-alternate .form-group:nth-child(odd) .form-group {
                            background-color: #f5f5f5;
                        }

                        .cea-alternate .form-group:nth-child(even) .form-group {
                            background-color: #FFF;
                        }

                        .cea-alternate {
                            min-height: 60px;
                            margin-bottom: 0;
                            position: relative;

                            .form-group {
                                margin-bottom: 0;
                                position: relative;
                            }
                        }
                    }
                }
            }
        }
    }
}

.cea-page-container {
    .btn.btn-accent {
        background-color: #bd0102;
        border-color: #bd0102;
        color: #fff !important;
    }

    .btn.btn-accent:active, .btn.btn-accent.active {
        background-color: #8a0101;
        border-color: #7e0101;
    }

    .btn.btn-accent:hover, .btn.btn-accent:focus {
        background-color: #970001;
        border-color: #8a0101;
    }
}


.back-column, .cancel-column, .download-column, .addto-column {
    float: left;
    width: 100%;
    padding-left: 6px;
    padding-right: 6px;
    position: relative;
    min-height: 1px;
    text-align: center;
    border-bottom-width: 0px;
    border-top-width: 0px;
    border-left-width: 0px;
    padding-top: 0;
    padding-bottom: 0;
}

@container cea-container (min-width: #{$screen-sm}) {
    .header-language {
        margin-right: 35px;
    }
}


@container cea-container (min-width: #{$screen-lg}) {
    .container {
        width: 1170px;
    }
}

@container cea-container (min-width: #{$screen-md}) {
    .container {
        width: 970px;
    }
}

@container cea-container (min-width: #{$screen-sm}) {
    .container {
        //width: 750px;
    }
}

@container cea-container (min-width: #{$screen-md}) {
    .back-column {
        float: left;
        width: 50%;
    }
}

@container cea-container (min-width: #{$screen-sm}) {
    .back-column {
        float: left;
        width: 50%;
    }
}

@container cea-container (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
    .back-column {
        padding-left: 6px;
        padding-right: 6px;
        float: left;
        width: 100%;
    }
}

@container cea-container (max-width: #{$screen-xxxs-max}) {
    .back-column {
        padding-left: 6px;
        padding-right: 6px;
        float: left;
        width: 100%;
    }
}

@container cea-container (min-width: #{$screen-md}) {
    .cancel-column {
        float: left;
        width: 50%;
    }
}

@container cea-container (min-width: #{$screen-sm}) {
    .cancel-column {
        float: left;
        width: 50%;
    }
}

@container cea-container (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
    .cancel-column {
        padding-left: 6px;
        padding-right: 6px;
        float: left;
        width: 100%;
    }
}

@container cea-container (max-width: #{$screen-xxxs-max}) {
    .cancel-column {
        padding-left: 6px;
        padding-right: 6px;
        float: left;
        width: 100%;
    }
}


@container cea-container (min-width: #{$screen-md}) {
    .download-column {
        float: left;
        width: 50%;
    }
}

@container cea-container (min-width: #{$screen-sm}) {
    .download-column {
        float: left;
        width: 50%;
    }
}

@container cea-container (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
    .download-column {
        padding-left: 6px;
        padding-right: 6px;
        float: left;
        width: 100%;
    }
}

@container cea-container (max-width: #{$screen-xxxs-max}) {
    .download-column {
        padding-left: 6px;
        padding-right: 6px;
        float: left;
        width: 100%;
    }
}

@container cea-container (min-width: #{$screen-md}) {
    .addto-column {
        float: left;
        width: 50%;
    }
}

@container cea-container (min-width: #{$screen-sm}) {
    .addto-column {
        float: left;
        width: 50%;
    }
}

@container cea-container (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
    .addto-column {
        padding-left: 6px;
        padding-right: 6px;
        float: left;
        width: 100%;
    }
}

@container cea-container (max-width: #{$screen-xxxs-max}) {
    .addto-column {
        padding-left: 6px;
        padding-right: 6px;
        float: left;
        width: 100%;
    }
}

.cea-page-container {
    .gauges-container {
        display: flex;
    }
    /* ------ Default Style ---------- */

    mwl-gauge {
        flex: 1;
        display: block;
        /*padding: 10px;
  margin: 7px;*/
        border-radius: 3px;
    }

    mwl-gauge > .gauge > .dial {
        stroke: rgb(221, 226, 231);
        stroke-width: 10;
        fill: rgba(0, 0, 0, 0);
    }

    mwl-gauge > .gauge > .value {
        stroke: rgb(153, 153, 153);
        stroke-width: 10;
        fill: rgba(0, 0, 0, 0);
    }

    mwl-gauge > .gauge > .value-text {
        /*fill: rgb(47, 227, 255);
  font-family: sans-serif;
  //font-weight: bold;
  font-size: 7px ;*/
        text-anchor: middle;
        font-family: "open sans serif", Helvetica, Arial, Verdana, Tahoma, sans-serif;
        font-size: 10px;
        font-weight: normal;
        fill-opacity: 1;
    }

    .btn {
        padding: .375rem .75rem;
        margin-bottom: 8px;
        text-transform: uppercase;
        border-radius: 0;
        white-space: nowrap;
        vertical-align: initial;
        font-size: 12px;
    }

    .btn-link {
        color: #07819c !important;
    }

    svg {
        fill: currentColor;

        .symbol-back {
            fill: #0B3C5D !important;
        }

        .symbol-border, .symbol {
            fill: #fff !important;
        }
    }

    .ngx-slider-pointer {
        border: none;
        outline: none;
    }

    .container {
        max-width: 100%;
    }

    .gauge-title {
        font-size: 24px;
        position: relative;
        width: 100%;
        text-align: center;
    }

    .gauge-subtitle {
        font-size: 24px;
        position: relative;
        width: 100%;
        text-align: center;
    }
}

@container cea-container (max-width: #{$screen-xs-max}) {
    .gauge-title {
        font-size: 14px;
    }
}


@container cea-container (max-width: #{$screen-xs-max}) {
    .gauge-subtitle {
        font-size: 14px;
    }
}

@container cea-container (max-width: #{$screen-xs-max}) {
    .gauge-subtitle {
        font-size: 10px;
    }
}

@container cea-container (max-width: #{$screen-xs}) {
    .header-container .detailed-header .title-font {
        font-size: 24px !important;
    }

    .header-container .detailed-header .title-font-es {
        font-size: 20px !important;
    }
}

@container cea-container (max-width: #{$screen-xs-max}) {
    .gauge-title {
        font-size: 14px !important;
    }

    .cea-page-header {
        //position: fixed;
        left: 0;
        width: 100%;
        top: 0;
        z-index: 1000;
    }

    .cea-page {
        margin-top: 10px;
    }
    // .detailed-header {
    //   padding-top: 0 !important;
    // }
    .gauge-subtitle {
        font-size: 13px !important;
        margin-bottom: 15px;
    }

    .row.cea-top-margin20 {
        display: flex;
        flex-direction: column-reverse;
    }
}

.cea-page-container {
    .form-group {
        margin-bottom: 0;
        position: relative;
    }

    .btn.disabled, .btn:disabled {
        opacity: 0.80 !important;
    }

    .retrofit {
        border: 2px solid #e4b548;
        font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        padding: .4em;
        text-align: center;
        font-size: 1.1em;
        font-weight: bold;
        margin-bottom: 1em;
    }

    .cea-col-xs-1, .cea-col-sm-1, .cea-col-md-1, .cea-col-lg-1, .cea-col-xs-2, .cea-col-sm-2, .cea-col-md-2, .cea-col-lg-2, .cea-col-xs-3, .cea-col-sm-3, .cea-col-md-3, .cea-col-lg-3, .cea-col-xs-4, .cea-col-sm-4, .cea-col-md-4, .cea-col-lg-4, .cea-col-xs-5, .cea-col-sm-5, .cea-col-md-5, .cea-col-lg-5, .cea-col-xs-6, .cea-col-sm-6, .cea-col-md-6, .cea-col-lg-6, .cea-col-xs-7, .cea-col-sm-7, .cea-col-md-7, .cea-col-lg-7, .cea-col-xs-8, .cea-col-sm-8, .cea-col-md-8, .cea-col-lg-8, .cea-col-xs-9, .cea-col-sm-9, .cea-col-md-9, .cea-col-lg-9, .cea-col-xs-10, .cea-col-sm-10, .cea-col-md-10, .cea-col-lg-10, .cea-col-xs-11, .cea-col-sm-11, .cea-col-md-11, .cea-col-lg-11, .cea-col-xs-12, .cea-col-sm-12, .cea-col-md-12, .cea-col-lg-12 {
        position: relative;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .cea-col-xs-1, .cea-col-xs-2, .cea-col-xs-3, .cea-col-xs-4, .cea-col-xs-5, .cea-col-xs-6, .cea-col-xs-7, .cea-col-xs-8, .cea-col-xs-9, .cea-col-xs-10, .cea-col-xs-11, .cea-col-xs-12 {
        float: left;
        padding-right: 15px;
        padding-left: 15px;
    }

    .cea-col-xs-12 {
        width: 100%;
    }

    .cea-col-xs-11 {
        width: 91.66666667%;
    }

    .cea-col-xs-10 {
        width: 83.33333333%;
    }

    .cea-col-xs-9 {
        width: 75%;
    }

    .cea-col-xs-8 {
        width: 66.66666667%;
    }

    .cea-col-xs-7 {
        width: 58.33333333%;
    }

    .cea-col-xs-6 {
        width: 50%;
    }

    .cea-col-xs-5 {
        width: 41.66666667%;
    }

    .cea-col-xs-4 {
        width: 33.33333333%;
    }

    .cea-col-xs-3 {
        width: 25%;
    }

    .cea-col-xs-2 {
        width: 16.66666667%;
    }

    .cea-col-xs-1 {
        width: 8.33333333%;
    }

    .cea-col-xs-pull-12 {
        right: 100%;
    }

    .cea-col-xs-pull-11 {
        right: 91.66666667%;
    }

    .cea-col-xs-pull-10 {
        right: 83.33333333%;
    }

    .cea-col-xs-pull-9 {
        right: 75%;
    }

    .cea-col-xs-pull-8 {
        right: 66.66666667%;
    }

    .cea-col-xs-pull-7 {
        right: 58.33333333%;
    }

    .cea-col-xs-pull-6 {
        right: 50%;
    }

    .cea-col-xs-pull-5 {
        right: 41.66666667%;
    }

    .cea-col-xs-pull-4 {
        right: 33.33333333%;
    }

    .cea-col-xs-pull-3 {
        right: 25%;
    }

    .cea-col-xs-pull-2 {
        right: 16.66666667%;
    }

    .cea-col-xs-pull-1 {
        right: 8.33333333%;
    }

    .cea-col-xs-pull-0 {
        right: auto;
    }

    .cea-col-xs-push-12 {
        left: 100%;
    }

    .cea-col-xs-push-11 {
        left: 91.66666667%;
    }

    .cea-col-xs-push-10 {
        left: 83.33333333%;
    }

    .cea-col-xs-push-9 {
        left: 75%;
    }

    .cea-col-xs-push-8 {
        left: 66.66666667%;
    }

    .cea-col-xs-push-7 {
        left: 58.33333333%;
    }

    .cea-col-xs-push-6 {
        left: 50%;
    }

    .cea-col-xs-push-5 {
        left: 41.66666667%;
    }

    .cea-col-xs-push-4 {
        left: 33.33333333%;
    }

    .cea-col-xs-push-3 {
        left: 25%;
    }

    .cea-col-xs-push-2 {
        left: 16.66666667%;
    }

    .cea-col-xs-push-1 {
        left: 8.33333333%;
    }

    .cea-col-xs-push-0 {
        left: auto;
    }

    .cea-col-xs-offset-12 {
        margin-left: 100%;
    }

    .cea-col-xs-offset-11 {
        margin-left: 91.66666667%;
    }

    .cea-col-xs-offset-10 {
        margin-left: 83.33333333%;
    }

    .cea-col-xs-offset-9 {
        margin-left: 75%;
    }

    .cea-col-xs-offset-8 {
        margin-left: 66.66666667%;
    }

    .cea-col-xs-offset-7 {
        margin-left: 58.33333333%;
    }

    .cea-col-xs-offset-6 {
        margin-left: 50%;
    }

    .cea-col-xs-offset-5 {
        margin-left: 41.66666667%;
    }

    .cea-col-xs-offset-4 {
        margin-left: 33.33333333%;
    }

    .cea-col-xs-offset-3 {
        margin-left: 25%;
    }

    .cea-col-xs-offset-2 {
        margin-left: 16.66666667%;
    }

    .cea-col-xs-offset-1 {
        margin-left: 8.33333333%;
    }

    .cea-col-xs-offset-0 {
        margin-left: 0%;
    }
}


@container cea-container (min-width: #{$screen-sm}) {
    .cea-col-sm-1, .cea-col-sm-2, .cea-col-sm-3, .cea-col-sm-4, .cea-col-sm-5, .cea-col-sm-6, .cea-col-sm-7, .cea-col-sm-8, .cea-col-sm-9, .cea-col-sm-10, .cea-col-sm-11, .cea-col-sm-12 {
        float: left;
        padding-right: 15px;
        padding-left: 15px;
    }

    .cea-col-sm-12 {
        width: 100%;
    }

    .cea-col-sm-11 {
        width: 91.66666667%;
    }

    .cea-col-sm-10 {
        width: 83.33333333%;
    }

    .cea-col-sm-9 {
        width: 75%;
    }

    .cea-col-sm-8 {
        width: 66.66666667%;
    }

    .cea-col-sm-7 {
        width: 58.33333333%;
    }

    .cea-col-sm-6 {
        width: 50%;
    }

    .cea-col-sm-5 {
        width: 41.66666667%;
    }

    .cea-col-sm-4 {
        width: 33.33333333%;
    }

    .cea-col-sm-3 {
        width: 25%;
    }

    .cea-col-sm-2 {
        width: 16.66666667%;
    }

    .cea-col-sm-1 {
        width: 8.33333333%;
    }

    .cea-col-sm-pull-12 {
        right: 100%;
    }

    .cea-col-sm-pull-11 {
        right: 91.66666667%;
    }

    .cea-col-sm-pull-10 {
        right: 83.33333333%;
    }

    .cea-col-sm-pull-9 {
        right: 75%;
    }

    .cea-col-sm-pull-8 {
        right: 66.66666667%;
    }

    .cea-col-sm-pull-7 {
        right: 58.33333333%;
    }

    .cea-col-sm-pull-6 {
        right: 50%;
    }

    .cea-col-sm-pull-5 {
        right: 41.66666667%;
    }

    .cea-col-sm-pull-4 {
        right: 33.33333333%;
    }

    .cea-col-sm-pull-3 {
        right: 25%;
    }

    .cea-col-sm-pull-2 {
        right: 16.66666667%;
    }

    .cea-col-sm-pull-1 {
        right: 8.33333333%;
    }

    .cea-col-sm-pull-0 {
        right: auto;
    }

    .cea-col-sm-push-12 {
        left: 100%;
    }

    .cea-col-sm-push-11 {
        left: 91.66666667%;
    }

    .cea-col-sm-push-10 {
        left: 83.33333333%;
    }

    .cea-col-sm-push-9 {
        left: 75%;
    }

    .cea-col-sm-push-8 {
        left: 66.66666667%;
    }

    .cea-col-sm-push-7 {
        left: 58.33333333%;
    }

    .cea-col-sm-push-6 {
        left: 50%;
    }

    .cea-col-sm-push-5 {
        left: 41.66666667%;
    }

    .cea-col-sm-push-4 {
        left: 33.33333333%;
    }

    .cea-col-sm-push-3 {
        left: 25%;
    }

    .cea-col-sm-push-2 {
        left: 16.66666667%;
    }

    .cea-col-sm-push-1 {
        left: 8.33333333%;
    }

    .cea-col-sm-push-0 {
        left: auto;
    }

    .cea-col-sm-offset-12 {
        margin-left: 100%;
    }

    .cea-col-sm-offset-11 {
        margin-left: 91.66666667%;
    }

    .cea-col-sm-offset-10 {
        margin-left: 83.33333333%;
    }

    .cea-col-sm-offset-9 {
        margin-left: 75%;
    }

    .cea-col-sm-offset-8 {
        margin-left: 66.66666667%;
    }

    .cea-col-sm-offset-7 {
        margin-left: 58.33333333%;
    }

    .cea-col-sm-offset-6 {
        margin-left: 50%;
    }

    .cea-col-sm-offset-5 {
        margin-left: 41.66666667%;
    }

    .cea-col-sm-offset-4 {
        margin-left: 33.33333333%;
    }

    .cea-col-sm-offset-3 {
        margin-left: 25%;
    }

    .cea-col-sm-offset-2 {
        margin-left: 16.66666667%;
    }

    .cea-col-sm-offset-1 {
        margin-left: 8.33333333%;
    }

    .cea-col-sm-offset-0 {
        margin-left: 0%;
    }
}

@container cea-container (min-width: #{$screen-md}) {
    .cea-col-md-1, .cea-col-md-2, .cea-col-md-3, .cea-col-md-4, .cea-col-md-5, .cea-col-md-6, .cea-col-md-7, .cea-col-md-8, .cea-col-md-9, .cea-col-md-10, .cea-col-md-11, .cea-col-md-12 {
        float: left;
        padding-right: 15px;
        padding-left: 15px;
    }

    .cea-col-md-12 {
        width: 100%;
    }

    .cea-col-md-11 {
        width: 91.66666667%;
    }

    .cea-col-md-10 {
        width: 83.33333333%;
    }

    .cea-col-md-9 {
        width: 75%;
    }

    .cea-col-md-8 {
        width: 66.66666667%;
    }

    .cea-col-md-7 {
        width: 58.33333333%;
    }

    .cea-col-md-6 {
        width: 50%;
    }

    .cea-col-md-5 {
        width: 41.66666667%;
    }

    .cea-col-md-4 {
        width: 33.33333333%;
    }

    .cea-col-md-3 {
        width: 25%;
    }

    .cea-col-md-2 {
        width: 16.66666667%;
    }

    .cea-col-md-1 {
        width: 8.33333333%;
    }

    .cea-col-md-pull-12 {
        right: 100%;
    }

    .cea-col-md-pull-11 {
        right: 91.66666667%;
    }

    .cea-col-md-pull-10 {
        right: 83.33333333%;
    }

    .cea-col-md-pull-9 {
        right: 75%;
    }

    .cea-col-md-pull-8 {
        right: 66.66666667%;
    }

    .cea-col-md-pull-7 {
        right: 58.33333333%;
    }

    .cea-col-md-pull-6 {
        right: 50%;
    }

    .cea-col-md-pull-5 {
        right: 41.66666667%;
    }

    .cea-col-md-pull-4 {
        right: 33.33333333%;
    }

    .cea-col-md-pull-3 {
        right: 25%;
    }

    .cea-col-md-pull-2 {
        right: 16.66666667%;
    }

    .cea-col-md-pull-1 {
        right: 8.33333333%;
    }

    .cea-col-md-pull-0 {
        right: auto;
    }

    .cea-col-md-push-12 {
        left: 100%;
    }

    .cea-col-md-push-11 {
        left: 91.66666667%;
    }

    .cea-col-md-push-10 {
        left: 83.33333333%;
    }

    .cea-col-md-push-9 {
        left: 75%;
    }

    .cea-col-md-push-8 {
        left: 66.66666667%;
    }

    .cea-col-md-push-7 {
        left: 58.33333333%;
    }

    .cea-col-md-push-6 {
        left: 50%;
    }

    .cea-col-md-push-5 {
        left: 41.66666667%;
    }

    .cea-col-md-push-4 {
        left: 33.33333333%;
    }

    .cea-col-md-push-3 {
        left: 25%;
    }

    .cea-col-md-push-2 {
        left: 16.66666667%;
    }

    .cea-col-md-push-1 {
        left: 8.33333333%;
    }

    .cea-col-md-push-0 {
        left: auto;
    }

    .cea-col-md-offset-12 {
        margin-left: 100%;
    }

    .cea-col-md-offset-11 {
        margin-left: 91.66666667%;
    }

    .cea-col-md-offset-10 {
        margin-left: 83.33333333%;
    }

    .cea-col-md-offset-9 {
        margin-left: 75%;
    }

    .cea-col-md-offset-8 {
        margin-left: 66.66666667%;
    }

    .cea-col-md-offset-7 {
        margin-left: 58.33333333%;
    }

    .cea-col-md-offset-6 {
        margin-left: 50%;
    }

    .cea-col-md-offset-5 {
        margin-left: 41.66666667%;
    }

    .cea-col-md-offset-4 {
        margin-left: 33.33333333%;
    }

    .cea-col-md-offset-3 {
        margin-left: 25%;
    }

    .cea-col-md-offset-2 {
        margin-left: 16.66666667%;
    }

    .cea-col-md-offset-1 {
        margin-left: 8.33333333%;
    }

    .cea-col-md-offset-0 {
        margin-left: 0%;
    }
}

@container cea-container (min-width: #{$screen-lg}) {
    .cea-col-lg-1, .cea-col-lg-2, .cea-col-lg-3, .cea-col-lg-4, .cea-col-lg-5, .cea-col-lg-6, .cea-col-lg-7, .cea-col-lg-8, .cea-col-lg-9, .cea-col-lg-10, .cea-col-lg-11, .cea-col-lg-12 {
        float: left;
        padding-right: 15px;
        padding-left: 15px;
    }

    .cea-col-lg-12 {
        width: 100%;
    }

    .cea-col-lg-11 {
        width: 91.66666667%;
    }

    .cea-col-lg-10 {
        width: 83.33333333%;
    }

    .cea-col-lg-9 {
        width: 75%;
    }

    .cea-col-lg-8 {
        width: 66.66666667%;
    }

    .cea-col-lg-7 {
        width: 58.33333333%;
    }

    .cea-col-lg-6 {
        width: 50%;
    }

    .cea-col-lg-5 {
        width: 41.66666667%;
    }

    .cea-col-lg-4 {
        width: 33.33333333%;
    }

    .cea-col-lg-3 {
        width: 25%;
    }

    .cea-col-lg-2 {
        width: 16.66666667%;
    }

    .cea-col-lg-1 {
        width: 8.33333333%;
    }

    .cea-col-lg-pull-12 {
        right: 100%;
    }

    .cea-col-lg-pull-11 {
        right: 91.66666667%;
    }

    .cea-col-lg-pull-10 {
        right: 83.33333333%;
    }

    .cea-col-lg-pull-9 {
        right: 75%;
    }

    .cea-col-lg-pull-8 {
        right: 66.66666667%;
    }

    .cea-col-lg-pull-7 {
        right: 58.33333333%;
    }

    .cea-col-lg-pull-6 {
        right: 50%;
    }

    .cea-col-lg-pull-5 {
        right: 41.66666667%;
    }

    .cea-col-lg-pull-4 {
        right: 33.33333333%;
    }

    .cea-col-lg-pull-3 {
        right: 25%;
    }

    .cea-col-lg-pull-2 {
        right: 16.66666667%;
    }

    .cea-col-lg-pull-1 {
        right: 8.33333333%;
    }

    .cea-col-lg-pull-0 {
        right: auto;
    }

    .cea-col-lg-push-12 {
        left: 100%;
    }

    .cea-col-lg-push-11 {
        left: 91.66666667%;
    }

    .cea-col-lg-push-10 {
        left: 83.33333333%;
    }

    .cea-col-lg-push-9 {
        left: 75%;
    }

    .cea-col-lg-push-8 {
        left: 66.66666667%;
    }

    .cea-col-lg-push-7 {
        left: 58.33333333%;
    }

    .cea-col-lg-push-6 {
        left: 50%;
    }

    .cea-col-lg-push-5 {
        left: 41.66666667%;
    }

    .cea-col-lg-push-4 {
        left: 33.33333333%;
    }

    .cea-col-lg-push-3 {
        left: 25%;
    }

    .cea-col-lg-push-2 {
        left: 16.66666667%;
    }

    .cea-col-lg-push-1 {
        left: 8.33333333%;
    }

    .cea-col-lg-push-0 {
        left: auto;
    }

    .cea-col-lg-offset-12 {
        margin-left: 100%;
    }

    .cea-col-lg-offset-11 {
        margin-left: 91.66666667%;
    }

    .cea-col-lg-offset-10 {
        margin-left: 83.33333333%;
    }

    .cea-col-lg-offset-9 {
        margin-left: 75%;
    }

    .cea-col-lg-offset-8 {
        margin-left: 66.66666667%;
    }

    .cea-col-lg-offset-7 {
        margin-left: 58.33333333%;
    }

    .cea-col-lg-offset-6 {
        margin-left: 50%;
    }

    .cea-col-lg-offset-5 {
        margin-left: 41.66666667%;
    }

    .cea-col-lg-offset-4 {
        margin-left: 33.33333333%;
    }

    .cea-col-lg-offset-3 {
        margin-left: 25%;
    }

    .cea-col-lg-offset-2 {
        margin-left: 16.66666667%;
    }

    .cea-col-lg-offset-1 {
        margin-left: 8.33333333%;
    }

    .cea-col-lg-offset-0 {
        margin-left: 0%;
    }
}

@container cea-container (max-width: #{$screen-xs}) {
    .cea-col-xxs-12 {
        width: 100% !important;
    }
}

.cea-page-container {
    .row {
        flex-wrap: wrap !important;
        margin-right: -15px !important;
    }
    /* 
    .cea-visible-xss {
        display: none !important;
    }
        */
}


@container cea-container (max-width: #{$screen-xxs-max}) and (min-width: #{$screen-xxs}) {
    .cea-visible-xxs {
        display: block !important;
    }

    .cea-hidden-xxs {
        display: none !important;
    }
}

@container cea-container (max-width: #{$screen-xxxs-max}) {
    .cea-visible-xss {
        display: block !important;
    }

    .cea-hidden-xss {
        display: none !important;
    }
}
/* Non Container Supporter Browsers fallback */

@supports not (contain: inline-size) {
    @media (min-width: #{$screen-sm}) {
        .header-language {
            margin-right: 35px;
        }
    }

    @media (min-width: #{$screen-lg}) {
        .container {
            width: 1170px;
        }
    }

    @media (min-width: #{$screen-md}) {
        .container {
            width: 970px;
        }
    }

    @media (min-width: #{$screen-sm}) {
        .container {
            //width: 750px;
        }
    }

    @media (min-width: #{$screen-md}) {
        .back-column {
            float: left;
            width: 50%;
        }
    }

    @media (min-width: #{$screen-sm}) {
        .back-column {
            float: left;
            width: 50%;
        }
    }

    @media (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
        .back-column {
            padding-left: 6px;
            padding-right: 6px;
            float: left;
            width: 100%;
        }
    }

    @media (max-width: #{$screen-xxxs-max}) {
        .back-column {
            padding-left: 6px;
            padding-right: 6px;
            float: left;
            width: 100%;
        }
    }


    @media (min-width: #{$screen-md}) {
        .download-column {
            float: left;
            width: 50%;
        }
    }

    @media (min-width: #{$screen-sm}) {
        .download-column {
            float: left;
            width: 50%;
        }
    }

    @media (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
        .download-column {
            padding-left: 6px;
            padding-right: 6px;
            float: left;
            width: 100%;
        }
    }

    @media (max-width: #{$screen-xxxs-max}) {
        .download-column {
            padding-left: 6px;
            padding-right: 6px;
            float: left;
            width: 100%;
        }
    }

    @media (min-width: #{$screen-md}) {
        .cancel-column {
            float: left;
            width: 50%;
        }
    }

    @media (min-width: #{$screen-sm}) {
        .cancel-column {
            float: left;
            width: 50%;
        }
    }

    @media (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
        .cancel-column {
            padding-left: 6px;
            padding-right: 6px;
            float: left;
            width: 100%;
        }
    }

    @media (max-width: #{$screen-xxxs-max}) {
        .cancel-column {
            padding-left: 6px;
            padding-right: 6px;
            float: left;
            width: 100%;
        }
    }

    @media (min-width: #{$screen-md}) {
        .addto-column {
            float: left;
            width: 50%;
        }
    }

    @media (min-width: #{$screen-sm}) {
        .addto-column {
            float: left;
            width: 50%;
        }
    }

    @media (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
        .addto-column {
            padding-left: 6px;
            padding-right: 6px;
            float: left;
            width: 100%;
        }
    }

    @media (max-width: #{$screen-xxxs-max}) {
        .addto-column {
            padding-left: 6px;
            padding-right: 6px;
            float: left;
            width: 100%;
        }
    }

    @media (max-width: #{$screen-xs-max}) {
        .gauge-title {
            font-size: 14px;
        }
    }

    @media (max-width: #{$screen-xs-max}) {
        .gauge-subtitle {
            font-size: 14px;
        }
    }

    @media (max-width: #{$screen-xs-max}) {
        .gauge-subtitle {
            font-size: 10px;
        }
    }

    @media (max-width: #{$screen-xs}) {
        .header-container .detailed-header .title-font {
            font-size: 24px !important;
        }

        .header-container .detailed-header .title-font-es {
            font-size: 20px !important;
        }
    }

    @media (max-width: #{$screen-xs-max}) {
        .gauge-title {
            font-size: 14px !important;
        }

        .cea-page-header {
            //position: fixed;
            left: 0;
            width: 100%;
            top: 0;
            z-index: 1000;
        }

        .cea-page {
            margin-top: 10px;
        }

        .gauge-subtitle {
            font-size: 13px !important;
            margin-bottom: 15px;
        }

        .row.cea-top-margin20 {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    @media (min-width: #{$screen-sm}) {
        .cea-col-sm-1, .cea-col-sm-2, .cea-col-sm-3, .cea-col-sm-4, .cea-col-sm-5, .cea-col-sm-6, .cea-col-sm-7, .cea-col-sm-8, .cea-col-sm-9, .cea-col-sm-10, .cea-col-sm-11, .cea-col-sm-12 {
            float: left;
        }

        .cea-col-sm-12 {
            width: 100%;
        }

        .cea-col-sm-11 {
            width: 91.66666667%;
        }

        .cea-col-sm-10 {
            width: 83.33333333%;
        }

        .cea-col-sm-9 {
            width: 75%;
        }

        .cea-col-sm-8 {
            width: 66.66666667%;
        }

        .cea-col-sm-7 {
            width: 58.33333333%;
        }

        .cea-col-sm-6 {
            width: 50%;
        }

        .cea-col-sm-5 {
            width: 41.66666667%;
        }

        .cea-col-sm-4 {
            width: 33.33333333%;
        }

        .cea-col-sm-3 {
            width: 25%;
        }

        .cea-col-sm-2 {
            width: 16.66666667%;
        }

        .cea-col-sm-1 {
            width: 8.33333333%;
        }

        .cea-col-sm-pull-12 {
            right: 100%;
        }

        .cea-col-sm-pull-11 {
            right: 91.66666667%;
        }

        .cea-col-sm-pull-10 {
            right: 83.33333333%;
        }

        .cea-col-sm-pull-9 {
            right: 75%;
        }

        .cea-col-sm-pull-8 {
            right: 66.66666667%;
        }

        .cea-col-sm-pull-7 {
            right: 58.33333333%;
        }

        .cea-col-sm-pull-6 {
            right: 50%;
        }

        .cea-col-sm-pull-5 {
            right: 41.66666667%;
        }

        .cea-col-sm-pull-4 {
            right: 33.33333333%;
        }

        .cea-col-sm-pull-3 {
            right: 25%;
        }

        .cea-col-sm-pull-2 {
            right: 16.66666667%;
        }

        .cea-col-sm-pull-1 {
            right: 8.33333333%;
        }

        .cea-col-sm-pull-0 {
            right: auto;
        }

        .cea-col-sm-push-12 {
            left: 100%;
        }

        .cea-col-sm-push-11 {
            left: 91.66666667%;
        }

        .cea-col-sm-push-10 {
            left: 83.33333333%;
        }

        .cea-col-sm-push-9 {
            left: 75%;
        }

        .cea-col-sm-push-8 {
            left: 66.66666667%;
        }

        .cea-col-sm-push-7 {
            left: 58.33333333%;
        }

        .cea-col-sm-push-6 {
            left: 50%;
        }

        .cea-col-sm-push-5 {
            left: 41.66666667%;
        }

        .cea-col-sm-push-4 {
            left: 33.33333333%;
        }

        .cea-col-sm-push-3 {
            left: 25%;
        }

        .cea-col-sm-push-2 {
            left: 16.66666667%;
        }

        .cea-col-sm-push-1 {
            left: 8.33333333%;
        }

        .cea-col-sm-push-0 {
            left: auto;
        }

        .cea-col-sm-offset-12 {
            margin-left: 100%;
        }

        .cea-col-sm-offset-11 {
            margin-left: 91.66666667%;
        }

        .cea-col-sm-offset-10 {
            margin-left: 83.33333333%;
        }

        .cea-col-sm-offset-9 {
            margin-left: 75%;
        }

        .cea-col-sm-offset-8 {
            margin-left: 66.66666667%;
        }

        .cea-col-sm-offset-7 {
            margin-left: 58.33333333%;
        }

        .cea-col-sm-offset-6 {
            margin-left: 50%;
        }

        .cea-col-sm-offset-5 {
            margin-left: 41.66666667%;
        }

        .cea-col-sm-offset-4 {
            margin-left: 33.33333333%;
        }

        .cea-col-sm-offset-3 {
            margin-left: 25%;
        }

        .cea-col-sm-offset-2 {
            margin-left: 16.66666667%;
        }

        .cea-col-sm-offset-1 {
            margin-left: 8.33333333%;
        }

        .cea-col-sm-offset-0 {
            margin-left: 0%;
        }
    }

    @media (min-width: #{$screen-md}) {
        .cea-col-md-1, .cea-col-md-2, .cea-col-md-3, .cea-col-md-4, .cea-col-md-5, .cea-col-md-6, .cea-col-md-7, .cea-col-md-8, .cea-col-md-9, .cea-col-md-10, .cea-col-md-11, .cea-col-md-12 {
            float: left;
        }

        .cea-col-md-12 {
            width: 100%;
        }

        .cea-col-md-11 {
            width: 91.66666667%;
        }

        .cea-col-md-10 {
            width: 83.33333333%;
        }

        .cea-col-md-9 {
            width: 75%;
        }

        .cea-col-md-8 {
            width: 66.66666667%;
        }

        .cea-col-md-7 {
            width: 58.33333333%;
        }

        .cea-col-md-6 {
            width: 50%;
        }

        .cea-col-md-5 {
            width: 41.66666667%;
        }

        .cea-col-md-4 {
            width: 33.33333333%;
        }

        .cea-col-md-3 {
            width: 25%;
        }

        .cea-col-md-2 {
            width: 16.66666667%;
        }

        .cea-col-md-1 {
            width: 8.33333333%;
        }

        .cea-col-md-pull-12 {
            right: 100%;
        }

        .cea-col-md-pull-11 {
            right: 91.66666667%;
        }

        .cea-col-md-pull-10 {
            right: 83.33333333%;
        }

        .cea-col-md-pull-9 {
            right: 75%;
        }

        .cea-col-md-pull-8 {
            right: 66.66666667%;
        }

        .cea-col-md-pull-7 {
            right: 58.33333333%;
        }

        .cea-col-md-pull-6 {
            right: 50%;
        }

        .cea-col-md-pull-5 {
            right: 41.66666667%;
        }

        .cea-col-md-pull-4 {
            right: 33.33333333%;
        }

        .cea-col-md-pull-3 {
            right: 25%;
        }

        .cea-col-md-pull-2 {
            right: 16.66666667%;
        }

        .cea-col-md-pull-1 {
            right: 8.33333333%;
        }

        .cea-col-md-pull-0 {
            right: auto;
        }

        .cea-col-md-push-12 {
            left: 100%;
        }

        .cea-col-md-push-11 {
            left: 91.66666667%;
        }

        .cea-col-md-push-10 {
            left: 83.33333333%;
        }

        .cea-col-md-push-9 {
            left: 75%;
        }

        .cea-col-md-push-8 {
            left: 66.66666667%;
        }

        .cea-col-md-push-7 {
            left: 58.33333333%;
        }

        .cea-col-md-push-6 {
            left: 50%;
        }

        .cea-col-md-push-5 {
            left: 41.66666667%;
        }

        .cea-col-md-push-4 {
            left: 33.33333333%;
        }

        .cea-col-md-push-3 {
            left: 25%;
        }

        .cea-col-md-push-2 {
            left: 16.66666667%;
        }

        .cea-col-md-push-1 {
            left: 8.33333333%;
        }

        .cea-col-md-push-0 {
            left: auto;
        }

        .cea-col-md-offset-12 {
            margin-left: 100%;
        }

        .cea-col-md-offset-11 {
            margin-left: 91.66666667%;
        }

        .cea-col-md-offset-10 {
            margin-left: 83.33333333%;
        }

        .cea-col-md-offset-9 {
            margin-left: 75%;
        }

        .cea-col-md-offset-8 {
            margin-left: 66.66666667%;
        }

        .cea-col-md-offset-7 {
            margin-left: 58.33333333%;
        }

        .cea-col-md-offset-6 {
            margin-left: 50%;
        }

        .cea-col-md-offset-5 {
            margin-left: 41.66666667%;
        }

        .cea-col-md-offset-4 {
            margin-left: 33.33333333%;
        }

        .cea-col-md-offset-3 {
            margin-left: 25%;
        }

        .cea-col-md-offset-2 {
            margin-left: 16.66666667%;
        }

        .cea-col-md-offset-1 {
            margin-left: 8.33333333%;
        }

        .cea-col-md-offset-0 {
            margin-left: 0%;
        }
    }

    @media (min-width: #{$screen-lg}) {
        .cea-col-lg-1, .cea-col-lg-2, .cea-col-lg-3, .cea-col-lg-4, .cea-col-lg-5, .cea-col-lg-6, .cea-col-lg-7, .cea-col-lg-8, .cea-col-lg-9, .cea-col-lg-10, .cea-col-lg-11, .cea-col-lg-12 {
            float: left;
        }

        .cea-col-lg-12 {
            width: 100%;
        }

        .cea-col-lg-11 {
            width: 91.66666667%;
        }

        .cea-col-lg-10 {
            width: 83.33333333%;
        }

        .cea-col-lg-9 {
            width: 75%;
        }

        .cea-col-lg-8 {
            width: 66.66666667%;
        }

        .cea-col-lg-7 {
            width: 58.33333333%;
        }

        .cea-col-lg-6 {
            width: 50%;
        }

        .cea-col-lg-5 {
            width: 41.66666667%;
        }

        .cea-col-lg-4 {
            width: 33.33333333%;
        }

        .cea-col-lg-3 {
            width: 25%;
        }

        .cea-col-lg-2 {
            width: 16.66666667%;
        }

        .cea-col-lg-1 {
            width: 8.33333333%;
        }

        .cea-col-lg-pull-12 {
            right: 100%;
        }

        .cea-col-lg-pull-11 {
            right: 91.66666667%;
        }

        .cea-col-lg-pull-10 {
            right: 83.33333333%;
        }

        .cea-col-lg-pull-9 {
            right: 75%;
        }

        .cea-col-lg-pull-8 {
            right: 66.66666667%;
        }

        .cea-col-lg-pull-7 {
            right: 58.33333333%;
        }

        .cea-col-lg-pull-6 {
            right: 50%;
        }

        .cea-col-lg-pull-5 {
            right: 41.66666667%;
        }

        .cea-col-lg-pull-4 {
            right: 33.33333333%;
        }

        .cea-col-lg-pull-3 {
            right: 25%;
        }

        .cea-col-lg-pull-2 {
            right: 16.66666667%;
        }

        .cea-col-lg-pull-1 {
            right: 8.33333333%;
        }

        .cea-col-lg-pull-0 {
            right: auto;
        }

        .cea-col-lg-push-12 {
            left: 100%;
        }

        .cea-col-lg-push-11 {
            left: 91.66666667%;
        }

        .cea-col-lg-push-10 {
            left: 83.33333333%;
        }

        .cea-col-lg-push-9 {
            left: 75%;
        }

        .cea-col-lg-push-8 {
            left: 66.66666667%;
        }

        .cea-col-lg-push-7 {
            left: 58.33333333%;
        }

        .cea-col-lg-push-6 {
            left: 50%;
        }

        .cea-col-lg-push-5 {
            left: 41.66666667%;
        }

        .cea-col-lg-push-4 {
            left: 33.33333333%;
        }

        .cea-col-lg-push-3 {
            left: 25%;
        }

        .cea-col-lg-push-2 {
            left: 16.66666667%;
        }

        .cea-col-lg-push-1 {
            left: 8.33333333%;
        }

        .cea-col-lg-push-0 {
            left: auto;
        }

        .cea-col-lg-offset-12 {
            margin-left: 100%;
        }

        .cea-col-lg-offset-11 {
            margin-left: 91.66666667%;
        }

        .cea-col-lg-offset-10 {
            margin-left: 83.33333333%;
        }

        .cea-col-lg-offset-9 {
            margin-left: 75%;
        }

        .cea-col-lg-offset-8 {
            margin-left: 66.66666667%;
        }

        .cea-col-lg-offset-7 {
            margin-left: 58.33333333%;
        }

        .cea-col-lg-offset-6 {
            margin-left: 50%;
        }

        .cea-col-lg-offset-5 {
            margin-left: 41.66666667%;
        }

        .cea-col-lg-offset-4 {
            margin-left: 33.33333333%;
        }

        .cea-col-lg-offset-3 {
            margin-left: 25%;
        }

        .cea-col-lg-offset-2 {
            margin-left: 16.66666667%;
        }

        .cea-col-lg-offset-1 {
            margin-left: 8.33333333%;
        }

        .cea-col-lg-offset-0 {
            margin-left: 0%;
        }
    }

    @media (max-width: #{$screen-xs}) {
        .cea-col-xxs-12 {
            width: 100%;
        }
    }

    @media (max-width: #{$screen-xxs-max}) and (min-width: #{$screen-xxs}) {
        .cea-visible-xxs {
            display: block !important;
        }

        .cea-hidden-xxs {
            display: none !important;
        }
    }

    @media (max-width: #{$screen-xxxs-max}) {
        .cea-visible-xss {
            display: block !important;
        }

        .cea-hidden-xss {
            display: none !important;
        }
    }
}
/* Compare SCSS */

.cea-page {
    .cea-main-view {
        .compare-area {
            height: 100%;
            font-size: 8px;

            div {
                .list-area {
                    background-color: white !important;
                    padding-left: 0px !important;
                    padding-right: 0px !important;
                    color: #FFF;
                    position: relative;
                    padding-bottom: 15px;

                    .compare-container-fluid {
                        margin-right: auto;
                        margin-left: auto;
                        padding-left: 15px;
                        padding-right: 15px;

                        .row {
                            .body-copy {
                                font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                                font-size: 16px;
                                color: #77787B;
                                word-wrap: break-word;
                            }

                            .margin-bottom-lg {
                                margin-bottom: 1.5em;
                            }

                            .padding-y-xs {
                                padding: 0 .25em;
                            }

                            .panels-column {
                                padding-left: 6px;
                                padding-right: 6px;
                                position: relative;
                                min-height: 1px;
                                text-align: center;
                                border-bottom-width: 0px;
                                border-top-width: 0px;
                                border-left-width: 0px;
                                padding-top: 6px;
                                padding-bottom: 6px;

                                .panel {
                                    border-width: 0px;
                                    box-shadow: 0 0 11px rgba(0, 0, 0, 0.2);
                                    border-radius: 0;
                                    margin-bottom: 20px;
                                    background-color: #fff;
                                    border: 1px solid transparent;

                                    .panel-body {
                                        min-height: 185px;
                                        text-align: center;
                                        padding: 15px;
                                    }

                                    .btn-primary {
                                        background-color: #1294b2;
                                        border-color: #1294b2;
                                    }

                                    .btn {
                                        white-space: initial;
                                        color: #fff;
                                        padding: .375rem .75rem;
                                        margin-bottom: 8px;
                                        text-transform: uppercase;
                                        font-weight: 700;
                                        border-radius: 0;
                                        font-size: 12px;
                                        vertical-align: initial;
                                        cursor: pointer;
                                        background-image: none;
                                        border: thin solid transparent;
                                        user-select: none;
                                    }

                                    .btn-block {
                                        display: block;
                                        width: 100%;
                                    }

                                    .btn-lg {
                                        line-height: 1.3333333;
                                    }
                                }
                            }

                            .panel {
                                border-width: 0px;
                                box-shadow: 0 0 11px rgba(0, 0, 0, 0.2);
                                border-radius: 0;
                                margin-bottom: 20px;
                                background-color: #fff;
                                border: 1px solid transparent;

                                .panel-body {
                                    min-height: 185px;
                                    text-align: center;
                                    padding: 15px;
                                }

                                .btn {
                                    white-space: initial;
                                    font-weight: 700 !important;
                                    border-radius: 0 !important;
                                    color: #fff;
                                    padding: .375rem .75rem;
                                    margin-bottom: 8px;
                                    text-transform: uppercase;
                                    font-size: 12px;
                                    vertical-align: initial;
                                    cursor: pointer;
                                    background-image: none;
                                    border: thin solid transparent;
                                    user-select: none;
                                }

                                .btn-primary {
                                    background-color: #1294b2;
                                    border-color: #1294b2;
                                }

                                .btn-block {
                                    display: block;
                                    width: 100%;
                                }

                                .btn-lg {
                                    line-height: 1.3333333;
                                }
                            }

                            @container cea-container (min-width: #{$screen-sm}) {
                                .panels-column {
                                    float: left;
                                    width: 33.33333333%;
                                }
                            }

                            @supports not (contain: inline-size) {
                                @media (min-width: #{$screen-sm}) {
                                    .panels-column {
                                        float: left;
                                        width: 33.33333333%;
                                    }
                                }
                            }

                            .estimate-list-column {
                                padding-left: 6px;
                                padding-right: 6px;
                                position: relative;
                                min-height: 1px;
                                text-align: center;
                                border-bottom-width: 0px;
                                border-top-width: 0px;
                                border-left-width: 0px;
                                padding-top: 6px;
                                padding-bottom: 6px;

                                .row {
                                    margin-left: -15px;
                                    margin-right: -15px;

                                    .header-language {
                                        float: right;
                                        margin-top: -30px;
                                    }

                                    @container cea-container (min-width: #{$screen-sm}) {
                                        .header-language {
                                            margin-right: 35px;
                                        }
                                    }

                                    @supports not (contain: inline-size) {
                                        @media (min-width: #{$screen-sm}) {
                                            .header-language {
                                                margin-right: 35px;
                                            }
                                        }
                                    }
                                }
                            }

                            @container cea-container (min-width: #{$screen-sm}) {
                                .estimate-list-column {
                                    float: left;
                                    width: 66.66666667%;
                                }
                            }

                            @supports not (contain: inline-size) {
                                @media (min-width: #{$screen-sm}) {
                                    .estimate-list-column {
                                        float: left;
                                        width: 66.66666667%;
                                    }
                                }
                            }

                            .compare-container-fluid {
                                margin-right: auto;
                                margin-left: auto;
                                padding-left: 15px;
                                padding-right: 15px;
                            }

                            .estimate-group {
                                color: #77787B !important;
                                border-top-width: 0px;
                                border-right-width: 0px;
                                border-left-width: 0px;
                                border-bottom-width: 0px;

                                .row {
                                    margin-left: -15px;
                                    margin-right: -15px;
                                }

                                .estimate-group-headers {
                                    border-top: 1px solid darkgrey !important;
                                    border-left: 1px solid darkgrey !important;
                                    border-right: 1px solid darkgrey !important;
                                    background: #dbdbdb;

                                    .select-column {
                                        float: left;
                                        width: 14.16666667%; //padding-left: 8px;
                                        //padding-right: 8px;
                                        position: relative;
                                        min-height: 1px;
                                        text-align: center;
                                        border-right: 1px solid darkgrey;
                                        border-bottom-width: 0px;
                                        border-top-width: 0px;
                                        border-left-width: 0px;
                                        padding: 10px 0;
                                    }

                                    @container cea-container (min-width: #{$screen-sm}) {
                                        .select-column {
                                            float: left;
                                            width: 14.16666667%;
                                        }
                                    }

                                    @container cea-container (min-width: #{$screen-sm}) {
                                        .monthly-column {
                                            float: left;
                                            width: 15.83333333%;
                                        }
                                    }

                                    @supports not (contain: inline-size) {
                                        @media (min-width: #{$screen-sm}) {
                                            .select-column {
                                                float: left;
                                                width: 14.16666667%;
                                            }
                                        }

                                        @media (min-width: #{$screen-sm}) {
                                            .monthly-column {
                                                float: left;
                                                width: 15.83333333%;
                                            }
                                        }
                                    }

                                    .monthly-column {
                                        float: left;
                                        width: 15.83333333%; //padding-left: 8px;
                                        //padding-right: 8px;
                                        position: relative;
                                        min-height: 1px;
                                        text-align: center;
                                        border-right: 1px solid darkgrey;
                                        border-bottom-width: 0px;
                                        border-top-width: 0px;
                                        border-left-width: 0px;
                                        padding: 10px 0;
                                    }

                                    .annually-column {
                                        //padding-left: 8px;
                                        //padding-right: 8px;
                                        position: relative;
                                        min-height: 1px;
                                        text-align: center;
                                        border-right: 1px solid darkgrey;
                                        border-bottom-width: 0px;
                                        border-top-width: 0px;
                                        border-left-width: 0px;
                                        padding: 10px 0;
                                    }

                                    @container cea-container (min-width: #{$screen-sm}) {
                                        .annually-column {
                                            float: left;
                                            width: 15.83333333%;
                                        }
                                    }

                                    @supports not (contain: inline-size) {
                                        @media (min-width: #{$screen-sm}) {
                                            .annually-column {
                                                float: left;
                                                width: 15.83333333%;
                                            }
                                        }
                                    }

                                    .type-column {
                                        //padding-left: 8px;
                                        //padding-right: 8px;
                                        position: relative;
                                        min-height: 1px;
                                        text-align: center;
                                        border-right: 1px solid darkgrey;
                                        border-bottom-width: 0px;
                                        border-top-width: 0px;
                                        border-left-width: 0px;
                                        padding: 10px 0;
                                        text-transform: uppercase;
                                        font-weight: bold;
                                    }

                                    @container cea-container (min-width: #{$screen-sm}) {
                                        .type-column {
                                            float: left;
                                            width: 15.83333333%;
                                        }
                                    }

                                    @supports not (contain: inline-size) {
                                        @media (min-width: #{$screen-sm}) {
                                            .type-column {
                                                float: left;
                                                width: 15.83333333%;
                                            }
                                        }
                                    }

                                    .actions-column {
                                        //padding-left: 8px;
                                        position: relative;
                                        min-height: 1px;
                                        text-align: center;
                                        border-right: 1px solid darkgrey;
                                        border-bottom-width: 0px;
                                        border-top-width: 0px;
                                        border-left-width: 0px;
                                        padding: 10px 0;
                                        padding-right: 0px;
                                        white-space: nowrap;
                                    }

                                    .estimate-group-column-header {
                                        background-color: #dbdbdb;
                                        text-transform: uppercase;
                                        font-size: 12px;
                                        height: 100%;
                                        color: #919294;
                                    }
                                }

                                div {
                                    div {
                                        .body-copy-small {
                                            font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                                            color: #77787B;
                                            overflow-wrap: break-word;
                                            word-wrap: break-word;
                                            font-size: 14px;
                                        }
                                    }
                                }

                                .estimate-group-item:nth-of-type(2n) {
                                    background-color: white !important;
                                }

                                .estimate-group-item {
                                    margin-bottom: -1px;
                                    border-bottom-color: #999;
                                    border-bottom-style: solid;
                                    border-top-color: #999;
                                    border-top-style: solid;
                                    cursor: default;
                                    color: #77787B !important;
                                    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
                                    font-size: 10px !important;
                                    margin-left: -15px !important;
                                    margin-right: -15px !important;
                                    font-weight: 300 !important;

                                    .select-column {
                                        //padding-left: 8px;
                                        //padding-right: 8px;
                                        position: relative;
                                        min-height: 1px;
                                        text-align: center;
                                        border-right: 1px solid darkgrey;
                                        border-bottom-width: 0px;
                                        border-top-width: 0px;
                                        border-left-width: 0px;
                                        padding: 10px 0;
                                    }

                                    @container cea-container (min-width: #{$screen-sm}) {
                                        .select-column {
                                            float: left;
                                            width: 14.16666667%;
                                        }
                                    }

                                    @supports not (contain: inline-size) {
                                        @media (min-width: #{$screen-sm}) {
                                            .select-column {
                                                float: left;
                                                width: 14.16666667%;
                                            }
                                        }
                                    }

                                    .selected {
                                        background-color: #4D7EA6 !important;
                                        color: #FFF !important;
                                    }

                                    .monthly-column {
                                        //padding-left: 8px;
                                        //padding-right: 8px;
                                        position: relative;
                                        min-height: 1px;
                                        text-align: center;
                                        border-right: 1px solid darkgrey;
                                        border-bottom-width: 0px;
                                        border-top-width: 0px;
                                        border-left-width: 0px;
                                        padding: 10px 0;
                                    }

                                    @container cea-container (min-width: #{$screen-sm}) {
                                        .monthly-column {
                                            float: left;
                                            width: 15.83333333%;
                                        }
                                    }

                                    @supports not (contain: inline-size) {
                                        @media (min-width: #{$screen-sm}) {
                                            .monthly-column {
                                                float: left;
                                                width: 15.83333333%;
                                            }
                                        }
                                    }

                                    .annually-column {
                                        //padding-left: 8px;
                                        //padding-right: 8px;
                                        position: relative;
                                        min-height: 1px;
                                        text-align: center;
                                        border-right: 1px solid darkgrey;
                                        border-bottom-width: 0px;
                                        border-top-width: 0px;
                                        border-left-width: 0px;
                                        padding: 10px 0;
                                    }

                                    @container cea-container (min-width: #{$screen-sm}) {
                                        .annually-column {
                                            float: left;
                                            width: 15.83333333%;
                                        }
                                    }

                                    @supports not (contain: inline-size) {
                                        @media (min-width: #{$screen-sm}) {
                                            .annually-column {
                                                float: left;
                                                width: 15.83333333%;
                                            }
                                        }
                                    }

                                    .type-column {
                                        //padding-left: 8px;
                                        //padding-right: 8px;
                                        position: relative;
                                        min-height: 1px;
                                        text-align: center;
                                        border-right: 1px solid darkgrey;
                                        border-bottom-width: 0px;
                                        border-top-width: 0px;
                                        border-left-width: 0px;
                                        padding: 10px 0;
                                        text-transform: uppercase;
                                    }

                                    .actions-column {
                                        //padding-left: 8px;
                                        //padding-right: 8px;
                                        position: relative;
                                        min-height: 1px;
                                        text-align: center;
                                        border-right: 1px solid darkgrey;
                                        border-bottom-width: 0px;
                                        border-top-width: 0px;
                                        border-left-width: 0px;
                                        padding: 10px 0;
                                        border-right-width: 0px;
                                        padding-right: 0px;
                                        white-space: nowrap;

                                        .btn {
                                            font-weight: 700;
                                            border-radius: 0;
                                            white-space: nowrap;
                                            vertical-align: initial;
                                        }

                                        @container cea-container (max-width: #{$screen-sm-max}) and (min-width: #{$screen-sm}) {
                                            .estimate-button {
                                                padding-left: 4px !important;
                                                padding-right: 4px !important;
                                            }
                                        }

                                        @supports not (contain: inline-size) {
                                            @media (max-width: #{$screen-sm-max}) and (min-width: #{$screen-sm}) {
                                                .estimate-button {
                                                    padding-left: 4px !important;
                                                    padding-right: 4px !important;
                                                }
                                            }
                                        }

                                        .estimate-button {
                                            font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
                                            background-color: transparent !important;
                                            color: #505050 !important;
                                            text-transform: uppercase !important;
                                            border: solid #424242 2px !important;
                                            font-weight: 700 !important;
                                            border-radius: 0 !important;
                                        }
                                        //.btn {
                                        //  display: inline-block;
                                        //  text-align: center;
                                        //  cursor: pointer;
                                        //  background-image: none;
                                        //  user-select: none;
                                        //}
                                    }

                                    @container cea-container (min-width: #{$screen-sm}) {
                                        .actions-column {
                                            float: left;
                                            width: 25%;
                                        }
                                    }

                                    @supports not (contain: inline-size) {
                                        @media (min-width: #{$screen-sm}) {
                                            .actions-column {
                                                float: left;
                                                width: 25%;
                                            }
                                        }
                                    }
                                }

                                .row.no-gutters {
                                    div {
                                        .btn-accent {
                                            background-color: #bd0102;
                                            border-color: #bd0102;
                                        }

                                        .btn {
                                            color: #fff;
                                            padding: .375rem .75rem;
                                            margin-bottom: 8px;
                                            text-transform: uppercase;
                                            white-space: nowrap;
                                            vertical-align: initial;
                                            font-weight: 700 !important;
                                            border-radius: 0 !important;
                                        }

                                        .btn-block {
                                            display: block;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.cea-page-container {
    .blue-key {
        color: #77787B;
    }

    :host .row {
        display: flex;
        flex-wrap: wrap;
    }

    .first {
        font-weight: bold;
    }
}


@container cea-container (min-width: #{$screen-sm}) {
    .estimate-group .type-column {
        float: left;
        width: 29.16666667% !important;
    }
}

.cea-details {
    div {
        #report-body {
            .address-text {
                clear: both;
                text-align: center !important;
                margin-top: 10px;
                margin-bottom: 10px;
                font-size: 2em;
                font-weight: 700;
            }
        }

        .row {
            .report-header-logo {
                margin-bottom: 0;
                text-align: center;

                .details-text {
                    text-align: center !important;
                    font-size: 1.7em;
                    margin-top: 8px;
                }
            }

            .report-header-text {
                text-align: center;

                .details-header {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    font-size: 3.8em;
                    text-transform: uppercase;
                }

                @container cea-container (min-width: #{$screen-sm}) {
                    .details-header {
                        text-align: left;
                    }
                }

                .details-text {
                    margin-bottom: 5px;
                    font-size: 1.7em;
                }

                @container cea-container (min-width: #{$screen-sm}) {
                    .details-text {
                        text-align: left;
                    }
                }

                @supports not (contain: inline-size) {
                    @media (min-width: #{$screen-sm}) {
                        .details-header {
                            text-align: left;
                        }
                    }

                    @media (min-width: #{$screen-sm}) {
                        .details-text {
                            text-align: left;
                        }
                    }
                }
            }

            @container cea-container (min-width: #{$screen-sm}) {
                .report-header-text {
                    text-align: left;
                }
            }

            @supports not (contain: inline-size) {
                @media (min-width: #{$screen-sm}) {
                    .report-header-text {
                        text-align: left;
                    }
                }
            }
        }
    }
}

.cea-page-container {
    .new-cea-table {
        // background:red;
        tr {
            td {
                border-right: 0 solid #AAA;
                min-width: 8.5em;
                max-width: 8.5em;
                height: 1.5em;
                min-height: 1.5em;
                max-height: 1.5em;
                border-top: 0 solid #D9D9D9;
                padding: 1px 5px 1px 20px;
                font-size: 2em;
            }

            .first {
                padding: 1px 5px 1px 10px;
            }

            .ceavalue {
                text-align: right;
            }
        }
    }

    .cea-top-margin20 {
        div {
            .btn {
                font-size: 11pt !important;
            }
        }
    }

    @container cea-container (max-width: #{$screen-xs}) {
        .cea-top-margin20 {
            div {
                .btn {
                    font-size: 8pt !important;
                }
            }
        }
    }

    .cea-top-margin15 {
        margin-top: 15px;

        div {
            .btn {
                color: #fff;
                padding: .375rem .75rem;
                margin-bottom: 8px;
                text-transform: uppercase;
                white-space: nowrap;
                vertical-align: initial;
                font-size: 12px;
                font-weight: 700 !important;
                border-radius: 0 !important;
            }

            .btn.btn-primary, .btn.cea-policy-btn {
                background-color: #1294b2;
                border-color: #1294b2;
            }
        }
    }

    @container cea-container (max-width: #{$screen-xs}) {
        .cea-top-margin15 {
            div {
                .btn {
                    font-size: 8pt !important;
                }
            }
        }
    }

    #report-footer-text {
        .details-text {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 2em;
            color: #000;
        }
    }

    .estimate-group-item {
        background: #eee !important;
        border-bottom-width: 0px !important;
        border-top-width: 0px !important;
        border-left: 1px solid darkgrey !important;
        border-right: 1px solid darkgrey !important;
    }

    .estimate-button {
        font-size: 8px !important;
        margin-top: -15px !important;
        margin-bottom: -10px !important;
        padding: 4px 10px !important;
    }

    .hide-big {
        display: none !important;
    }

    .estBtns.row, .newEstbtns.row {
        //display: flex;
    }
}



@container cea-container (max-width: #{$screen-sm}) and (min-width: 430px) {
    .hide-big {
        display: none !important;
    }
}

@container cea-container (max-width:429px) {
    .hide-big {
        display: block !important;
    }

    .row.estimate-group-item {
        margin-bottom: 20px !important;
        border: 1px solid #999 !important;
        padding: 0 !important;
    }

    .row.estimate-group-item div {
        width: 100%;
        border: 0 !important;
        font-weight: bold;
        font-size: 12px;
    }

    .row.estimate-group-item div.select-column {
        background: #EFEFEF !important;
    }

    .row.estimate-group-item div.actions-column button {
        font-size: 12px !important;
        padding: 6px 16px !important;
    }

    .monthly-column, .annually-column, .type-column {
        text-align: right !important;
        padding-right: 20px !important;
        padding-left: 20px !important;
    }

    .row.estimate-group-item div span {
        float: left;
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
    }

    .estimate-group-item {
        background: #fff !important;
    }

    .cea-details .row {
        display: block;
    }

    .cea-details, #report-footer-text {
        overflow: auto;
    }

    .ceadetails {
        width: 100%;
        overflow: auto;
    }

    #quoteListGroup .panels-column, .hide-small {
        display: none !important;
    }

    #report-header {
        display: flex;
        flex-direction: column-reverse;
    }

    .renterEstiHead {
        text-align: center !important;
    }

    #cea-page-1 .row {
        margin-left: 0;
        margin-right: 0;
    }
}

.cea-page-container {

    .tableBtns {
        text-align: center;
        font-size: 1.6em;
        font-weight: bold;
        color: black;
    }

    .tableBtns a {
        cursor: initial;
        font-size: 1.3em;
        color: #1294b2;
    }

    .tableBtns a:hover {
        text-decoration: underline;
        color: black;
    }

    .tableBtns a.noDis {
        cursor: pointer;
    }

    .tableBtns a.noDis:hover {
        color: #77787B;
    }

    .row.chosenPol {
        margin-top: 30px;
        margin-bottom: 10px;
        color: #333;
    }

    .chosenPol p {
        font-size: 16px;
        line-height: 24px;
    }

    h3 {
        color: #4d7ea6;
        font-size: 18px;
        font-size: 1.125rem;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
    }


    .compare-area .estimate-group {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .compare-area .container-fluid {
        margin-right: auto;
        margin-left: auto;
    }

    .btn.btn-accent {
        background-color: #bd0102;
        border-color: #bd0102;
        color: #fff !important;
    }

    .estimate-panel {
        border-radius: 0px;
        border-width: 1px;
        border-color: #777;
        box-shadow: none;
        border-style: double;
    }

    .panel-field-value {
        font-family: 'Fjalla One', Helvetica, Arial, Verdana, Tahoma, sans-serif;
        height: 30px;
        width: 66%;
        float: right;
        text-align: right;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: normal;
        font-style: normal;
        color: #595959;
    }

    .panel .panel-select-button.active, .panel .panel-select-button:active {
        background-color: #4D7EA6 !important;
        color: #FFF !important;
        border-width: 0px;
        border-radius: 0px;
        box-shadow: none;
    }

    .panel .panel-select-button {
        color: #5A5A5A;
        background-color: #EFEFEF;
        border-width: 0px;
        border-radius: 0px;
        font-family: 'Fjalla One', Helvetica, Arial, Verdana, Tahoma, sans-serif;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: normal;
        padding: 7px;
    }

    .panel .panel-body .panel-field-caption {
        padding-top: 3px;
        height: 30px;
        width: 34%;
        float: left;
        text-transform: uppercase;
        text-align: left;
        font-size: 11px;
        color: #777;
    }

    .panel .panel-body {
        min-height: 185px;
        text-align: center;
        padding: 15px;
    }

    .estimate-group {
        color: #77787B !important;
        border-top-width: 0px;
        border-right-width: 0px;
        border-left-width: 0px;
        border-bottom-width: 0px;
    }

    .estimate-group .select-column {
        //padding-left: 8px;
        //padding-right: 8px;
        position: relative;
        min-height: 1px;
        text-align: center;
        border-right: 1px solid darkgrey;
        border-bottom-width: 0px;
        border-top-width: 0px;
        border-left-width: 0px;
        padding: 10px 0;
    }
}

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    min-height: 16.42857143px;
}

.model-body {
    font-size: 16px;
}

.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}


@container cea-container (min-width: #{$screen-md}) {
    .estimate-group .select-column {
        float: left;
        width: 10.83333333%;
    }
}

@container cea-container (min-width: #{$screen-sm}) {
    .estimate-group .select-column {
        float: left;
        width: 14.16666667%;
    }
}

@container cea-container (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
    .estimate-group .select-column {
        padding-left: 2px;
        padding-right: 2px;
        float: left;
        width: 14.16666667%;
    }
}

@container cea-container (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
    .estimate-group .select-column {
        padding-left: 8px;
        padding-right: 8px;
        float: left;
        width: 33.33333333%;
    }
}

@container cea-container (max-width: #{$screen-xxxs-max}) {
    .estimate-group .select-column {
        padding-left: 8px;
        padding-right: 8px;
        float: left;
        width: 33.33333333%;
    }
}

@container cea-container (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
    .estimate-group .select-column {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

.estimate-group .monthly-column {
    //padding-left: 8px;
    //padding-right: 8px;
    position: relative;
    min-height: 1px;
    text-align: center;
    border-right: 1px solid darkgrey;
    border-bottom-width: 0px;
    border-top-width: 0px;
    border-left-width: 0px;
    padding: 10px 0;
}

@container cea-container (min-width: #{$screen-md}) {
    .estimate-group .monthly-column {
        float: left;
        width: 15%;
    }
}

@container cea-container (min-width: #{$screen-sm}) {
    .estimate-group .monthly-column {
        float: left;
        width: 15.83333333%;
    }
}

@container cea-container (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
    .estimate-group .monthly-column {
        padding-left: 2px;
        padding-right: 2px;
        float: left;
        width: 15.83333333%;
    }
}

@container cea-container (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
    .estimate-group .monthly-column {
        padding-left: 8px;
        padding-right: 8px;
        float: left;
        width: 25%;
    }
}

@container cea-container (max-width: #{$screen-xxxs-max}) {
    .estimate-group .monthly-column {
        padding-left: 8px;
        padding-right: 8px;
        float: left;
        width: 33.33333333%;
    }
}

@container cea-container (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
    .estimate-group .monthly-column {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

.cea-page-container {
    .estimate-group .annually-column {
        //padding-left: 8px;
        //padding-right: 8px;
        position: relative;
        min-height: 1px;
        text-align: center;
        border-right: 1px solid darkgrey;
        border-bottom-width: 0px;
        border-top-width: 0px;
        border-left-width: 0px;
        padding: 10px 0;
    }


    .estimate-group .type-column {
        //padding-left: 8px;
        //padding-right: 8px;
        position: relative;
        min-height: 1px;
        text-align: center;
        border-right: 1px solid darkgrey;
        border-bottom-width: 0px;
        border-top-width: 0px;
        border-left-width: 0px;
        padding: 10px 0;
        text-transform: uppercase;
    }

    .estimate-group .actions-column {
        //padding-left: 8px;
        //padding-right: 8px;
        position: relative;
        min-height: 1px;
        text-align: center;
        border-right: 1px solid darkgrey;
        border-bottom-width: 0px;
        border-top-width: 0px;
        border-left-width: 0px;
        padding: 10px 0;
        border-right-width: 0px; //padding-right: 0px;
        white-space: nowrap;
    }

    .estimate-button:hover, .estimate-button:active, .estimate-button:focus {
        background-color: #DDD !important;
        color: #505050 !important;
    }
}

@container cea-container (min-width: #{$screen-md}) {
    .estimate-group .annually-column {
        float: left;
        width: 15%;
    }
}

@container cea-container (min-width: #{$screen-sm}) {
    .estimate-group .annually-column {
        float: left;
        width: 15.83333333%;
    }
}

@container cea-container (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
    .estimate-group .annually-column {
        padding-left: 2px;
        padding-right: 2px;
        float: left;
        width: 15.83333333%;
    }
}

@container cea-container (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
    .estimate-group .annually-column {
        padding-left: 8px;
        padding-right: 8px;
        float: left;
        width: 25%;
    }
}

@container cea-container (max-width: #{$screen-xxxs-max}) {
    .estimate-group .annually-column {
        padding-left: 8px;
        padding-right: 8px;
        float: left;
        width: 33.33333333%;
    }
}

@container cea-container (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
    .estimate-group .annually-column {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}



@container cea-container (min-width: #{$screen-md}) {
    .estimate-group .type-column {
        float: left;
        width: 30%;
    }
}

@container cea-container (min-width: #{$screen-sm}) {
    .estimate-group .type-column {
        float: left;
        width: 29.16666667%;
    }
}

@container cea-container (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
    .estimate-group .type-column {
        padding-left: 2px;
        padding-right: 2px;
        float: left;
        width: 33.33333333%;
    }
}

@container cea-container (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
    .estimate-group .type-column {
        padding-left: 8px;
        padding-right: 8px;
        float: left;
        width: 25%;
    }
}

@container cea-container (max-width: #{$screen-xxxs-max}) {
    .estimate-group .type-column {
        padding-left: 8px;
        padding-right: 8px;
        float: left;
        width: 33.33333333%;
    }
}

@container cea-container (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
    .estimate-group .type-column {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}


@container cea-container (min-width: #{$screen-md}) {
    .estimate-group .actions-column {
        float: left;
        width: 29.16666667%;
    }
}

@container cea-container (min-width: #{$screen-sm}) {
    .estimate-group .actions-column {
        float: left;
        width: 25%;
    }
}

@container cea-container (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
    .estimate-group .actions-column {
        padding-left: 2px;
        padding-right: 2px;
        float: left;
        width: 20.166667%;
    }
}

@container cea-container (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
    .estimate-group .actions-column {
        padding-left: 8px;
        padding-right: 8px;
        float: left;
        width: 25%;
    }
}

@container cea-container (max-width: #{$screen-xxxs-max}) {
    .estimate-group .actions-column {
        padding-left: 8px;
        padding-right: 8px;
        float: left;
        width: 33.33333333%;
    }
}

@container cea-container (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
    .estimate-group .actions-column {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

@container cea-container (min-width: #{$screen-sm}) and (max-width: #{$screen-sm-max}) {
    .estimate-button {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }
}

@container cea-container (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
    .estimate-button {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }
}

.cea-page-container {
    .panel .panel-action-button {
        font-family: "Source Sans Pro", Helvetica, Arial, Verdana, Tahoma, sans-serif;
        color: #505050;
        font-size: 13px;
        background-color: transparent;
        border: solid #424242 2px;
        text-decoration: none;
        border-radius: 0px;
        text-transform: uppercase;
        font-weight: bold;
        padding: 5px 15px;
        margin-top: 8px;
    }

    .panel .panel-action-button:hover {
        background-color: #DDD;
    }

    .no-gutters > .cea-col, .no-gutters > [class*=cea-col-] {
        padding-left: 0px;
        padding-right: 0px;
    }

    .row {
        margin-left: -15px;
        margin-right: -15px;
    }

    .cea-col-desktop-only {
        display: none;
    }

    .margin-bottom {
        margin-bottom: 0px !important;
    }

    a {
        color: #fff !important;
        text-decoration: none !important;
    }

    .link-color {
        color: #1294b2 !important;
    }

    button.btn.btn-xs.panel-action-button {
        font-family: "Source Sans Pro", Helvetica, Arial, Verdana, Tahoma, sans-serif;
        color: #505050 !important;
        font-size: 13px;
        background-color: transparent;
        border: solid #424242 2px !important;
        text-decoration: none;
        border-radius: 0px;
        text-transform: uppercase;
        font-weight: bold;
        padding: 5px 15px;
        margin-top: 8px;
    }
}


@container cea-container (max-width: #{$screen-xs-max}) {
    .compare-area .report-header-logo-2 {
        display: none;
    }
}


@container cea-container (min-width: #{$screen-sm}) {
    .compare-area .report-header-logo-1 {
        display: none;
    }

    .compare-area .report-header-logo {
        margin-bottom: 0;
    }

    .cea-col-desktop-only {
        display: inline-block !important;
    }
}


@container cea-container (max-width: #{$screen-xs}) {
    .cea-col-xxs-12 {
        width: 100%;
    }
}

@supports not (contain: inline-size) {
    @media (min-width: #{$screen-sm}) {
        .estimate-group .type-column {
            float: left;
            width: 29.16666667% !important;
        }
    }

    @media (max-width: #{$screen-sm}) and (min-width: 430px) {
        .hide-big {
            display: none !important;
        }
    }

    @media (max-width:429px) {
        .hide-big {
            display: block !important;
        }

        .row.estimate-group-item {
            margin-bottom: 20px !important;
            border: 1px solid #999 !important;
            padding: 0 !important;
        }

        .row.estimate-group-item div {
            width: 100%;
            border: 0 !important;
            font-weight: bold;
            font-size: 12px;
        }

        .row.estimate-group-item div.select-column {
            background: #EFEFEF !important;
        }

        .row.estimate-group-item div.actions-column button {
            font-size: 12px !important;
            padding: 6px 16px !important;
        }

        .monthly-column, .annually-column, .type-column {
            text-align: right !important;
            padding-right: 20px !important;
            padding-left: 20px !important;
        }

        .row.estimate-group-item div span {
            float: left;
            font-size: 12px;
            font-weight: normal;
            text-transform: uppercase;
        }

        .estimate-group-item {
            background: #fff !important;
        }

        .cea-details .row {
            display: block;
        }

        .cea-details, #report-footer-text {
            overflow: auto;
        }

        .ceadetails {
            width: 100%;
            overflow: auto;
        }

        #quoteListGroup .panels-column, .hide-small {
            display: none !important;
        }

        #report-header {
            display: flex;
            flex-direction: column-reverse;
        }

        .renterEstiHead {
            text-align: center !important;
        }

        #cea-page-1 .row {
            margin-left: 0;
            margin-right: 0;
        }
    }

    @media (min-width: #{$screen-md}) {
        .estimate-group .select-column {
            float: left;
            width: 10.83333333%;
        }
    }

    @media (min-width: #{$screen-sm}) {
        .estimate-group .select-column {
            float: left;
            width: 14.16666667%;
        }
    }

    @media (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
        .estimate-group .select-column {
            padding-left: 2px;
            padding-right: 2px;
            float: left;
            width: 14.16666667%;
        }
    }

    @media (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
        .estimate-group .select-column {
            padding-left: 8px;
            padding-right: 8px;
            float: left;
            width: 33.33333333%;
        }
    }

    @media (max-width: #{$screen-xxxs-max}) {
        .estimate-group .select-column {
            padding-left: 8px;
            padding-right: 8px;
            float: left;
            width: 33.33333333%;
        }
    }

    @media (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
        .estimate-group .select-column {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    @media (min-width: #{$screen-md}) {
        .estimate-group .monthly-column {
            float: left;
            width: 15%;
        }
    }

    @media (min-width: #{$screen-sm}) {
        .estimate-group .monthly-column {
            float: left;
            width: 15.83333333%;
        }
    }

    @media (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
        .estimate-group .monthly-column {
            padding-left: 2px;
            padding-right: 2px;
            float: left;
            width: 15.83333333%;
        }
    }

    @media (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
        .estimate-group .monthly-column {
            padding-left: 8px;
            padding-right: 8px;
            float: left;
            width: 25%;
        }
    }

    @media (max-width: #{$screen-xxxs-max}) {
        .estimate-group .monthly-column {
            padding-left: 8px;
            padding-right: 8px;
            float: left;
            width: 33.33333333%;
        }
    }

    @media (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
        .estimate-group .monthly-column {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    @media (min-width: #{$screen-md}) {
        .estimate-group .annually-column {
            float: left;
            width: 15%;
        }
    }

    @media (min-width: #{$screen-sm}) {
        .estimate-group .annually-column {
            float: left;
            width: 15.83333333%;
        }
    }

    @media (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
        .estimate-group .annually-column {
            padding-left: 2px;
            padding-right: 2px;
            float: left;
            width: 15.83333333%;
        }
    }

    @media (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
        .estimate-group .annually-column {
            padding-left: 8px;
            padding-right: 8px;
            float: left;
            width: 25%;
        }
    }

    @media (max-width: #{$screen-xxxs-max}) {
        .estimate-group .annually-column {
            padding-left: 8px;
            padding-right: 8px;
            float: left;
            width: 33.33333333%;
        }
    }

    @media (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
        .estimate-group .annually-column {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    @media (min-width: #{$screen-md}) {
        .estimate-group .type-column {
            float: left;
            width: 30%;
        }
    }

    @media (min-width: #{$screen-sm}) {
        .estimate-group .type-column {
            float: left;
            width: 29.16666667%;
        }
    }

    @media (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
        .estimate-group .type-column {
            padding-left: 2px;
            padding-right: 2px;
            float: left;
            width: 33.33333333%;
        }
    }

    @media (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
        .estimate-group .type-column {
            padding-left: 8px;
            padding-right: 8px;
            float: left;
            width: 25%;
        }
    }

    @media (max-width: #{$screen-xxxs-max}) {
        .estimate-group .type-column {
            padding-left: 8px;
            padding-right: 8px;
            float: left;
            width: 33.33333333%;
        }
    }

    @media (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
        .estimate-group .type-column {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    @media (min-width: #{$screen-md}) {
        .estimate-group .actions-column {
            float: left;
            width: 29.16666667%;
        }
    }

    @media (min-width: #{$screen-sm}) {
        .estimate-group .actions-column {
            float: left;
            width: 25%;
        }
    }

    @media (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
        .estimate-group .actions-column {
            padding-left: 2px;
            padding-right: 2px;
            float: left;
            width: 20.166667%;
        }
    }

    @media (min-width: #{$screen-xxs}) and (max-width: #{$screen-xxs-max}) {
        .estimate-group .actions-column {
            padding-left: 8px;
            padding-right: 8px;
            float: left;
            width: 25%;
        }
    }

    @media (max-width: #{$screen-xxxs-max}) {
        .estimate-group .actions-column {
            padding-left: 8px;
            padding-right: 8px;
            float: left;
            width: 33.33333333%;
        }
    }

    @media (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
        .estimate-group .actions-column {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    @media (min-width: #{$screen-sm}) and (max-width: #{$screen-sm-max}) {
        .estimate-button {
            padding-left: 4px !important;
            padding-right: 4px !important;
        }
    }

    @media (min-width: #{$screen-xs}) and (max-width: #{$screen-xs-max}) {
        .estimate-button {
            padding-left: 4px !important;
            padding-right: 4px !important;
        }
    }

    @media (max-width: #{$screen-xs-max}) {
        .compare-area .report-header-logo-2 {
            display: none;
        }
    }

    @media (min-width: #{$screen-sm}) {
        .compare-area .report-header-logo-1 {
            display: none;
        }

        .compare-area .report-header-logo {
            margin-bottom: 0;
        }

        .cea-col-desktop-only {
            display: inline-block !important;
        }
    }

    @media (max-width: #{$screen-xs}) {
        .cea-col-xxs-12 {
            width: 100%;
        }
    }
}
