@import "./assets/scss/variables.scss";

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

//3rd Party libraries included in app are over-writting CSS code (to standardize tags)
//following code should fix the link color issue
.calc-home ~ div > a {
    color: #07819c !important;
}

.calc-home > a {
    color: #07819c !important;
}

.btn-secondary {
    color: #fff !important;
}

a {
    color: #07819c !important;
}


@media (min-width: #{$screen-sm}) and (max-width: #{$screen-sm-max}) {
    .modal-lg, .modal-xl {
        max-width: 650px;
    }
}

@media (min-width: #{$screen-md}) and (max-width: #{$screen-md-max}) {
    .modal-lg, .modal-xl {
        max-width: 880px;
    }
}

@media (min-width: #{$screen-lg}) {
    .modal-xl {
        max-width: 1000px;
    }
}

.row {
    display: block !important;
}

.create-dialog, .history-dialog {
    position: absolute !important;
    padding: 1.25em;
    top: 1%;
    left: 20%;
    border: 1px solid #000000;
    background-color: #ffffff;
}

.mat-mdc-snack-bar-container {
    color: rgba(255, 255, 255, 0.7);
    background: #0B3C5D;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    text-align: center;
    margin: auto !important;
    position: fixed;
    bottom: 1%;
    left: 40%;
}

.cdk-live-announcer-element {
    display: none;
}

.mat-mdc-snack-bar-action {
    color: #dcda77;
}
